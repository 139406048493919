<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :for="datepickerId"
      :is-field-inline="isFieldCustomStyle"
      :icon="labelIcon"
      :has-error="hasError"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div>
      <div
        class="relative"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      >
        <input
          ref="datepickerRef"
          :id="datepickerId"
          class="field border border-gray-300 text-xs rounded-lg focus:ring-0 block w-full pl-9 pr-2 py-1 transition-colors peer"
          :class="[
            fieldClass,
            {
              highlight: isHighlighted,
              'bg-white hover:border-gray-500': !disabled && !hasError,
              'bg-gray-50 text-gray-400 pointer-events-none': disabled,
              'border-red-300 bg-red-50 focus:border-red-500': hasError,
            },
          ]"
          type="date"
          placeholder="../../...."
          :disabled="disabled"
          v-model="innerValue"
          @focus="handleFocus"
          @blur="handleBlur"
          @copy="handleCopy"
          @paste="handlePaste"
        />

        <label
          class="absolute inset-y-0 left-2 flex items-center transition-colors"
          :class="{
            'text-gray-400': disabled,
            'text-gray-500 peer-focus:text-blue-600': !disabled,
          }"
          :for="datepickerId"
        >
          <Component :is="fieldIcon ?? CalendarIcon" class="w-5 h-5" />
        </label>
      </div>

      <p v-if="subText" class="mt-1 text-gray-500">
        {{ subText }}
      </p>
      <p v-if="hasError && errorMessage" class="mt-0.5 text-red-600 text-2xs">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { CalendarIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useDateHelpers } from '@/utils/composables/date.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useFormatHelper } from '@/utils/composables/format.helpers'

import type { Component } from 'vue'

const props = defineProps<{
  modelValue: string | null
  label?: string
  fieldClass?: string | Record<string, boolean>
  disabled?: boolean
  isHighlighted?: boolean
  labelIcon?: Component
  labelIconTooltipText?: string
  hasError?: boolean
  errorMessage?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
  fieldIcon?: Component
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<string | null>(props.modelValue)

const datepickerId = ref<string>(crypto.randomUUID())

const handleFocus = (event: Event): void => {
  if ('showPicker' in (event.target as HTMLInputElement)) {
    ;(event.target as HTMLInputElement).showPicker?.()
  }
  emit('focus')
}

const handleBlur = (): void => {
  emit('blur')
  emit('update:modelValue', innerValue.value || null)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

const { isStringDateValid } = useDateHelpers()
const { formatDatetime } = useFormatHelper()

const handleCopy = (): void => {
  if (innerValue.value) {
    navigator.clipboard.writeText(
      formatDatetime(innerValue.value, {
        format: 'DD/MM/YYYY',
        parsingFormat: ['DD/MM/YYYY', 'YYYY-MM-DD'],
      }),
    )
  }
}

const handlePaste = async (): Promise<void> => {
  const pastedValue = await navigator.clipboard.readText()

  if (pastedValue && isStringDateValid(pastedValue)) {
    innerValue.value = formatDatetime(pastedValue, {
      format: 'YYYY-MM-DD',
      parsingFormat: ['DD/MM/YYYY', 'YYYY-MM-DD'],
    })
  }
}
</script>

<style>
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
