import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 1,
  class: "absolute -top-2 -right-3 bg-red-600 text-white w-6 h-6> rounded-full text-xs"
}

import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'
import { useAnalytics } from '@/utils/composables/segment'
import { IEvent } from '@/utils/tracking'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
    badgeCount: {},
    disabled: { type: Boolean },
    isLoading: { type: Boolean },
    trackObject: {},
    trackProps: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { trackEvent } = useAnalytics()

const handleClick = (event: Event) => {
  if (props.trackObject) {
    trackEvent(props.trackObject, props.trackProps)
  }

  emit('click', event)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["relative text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center leading-6 transition-colors gap-x-2 min-h-[32px]", {
      'focus:ring-0': _ctx.disabled,
    }]),
    disabled: _ctx.disabled || _ctx.isLoading,
    onClick: handleClick
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(SpinnerIcon, {
          key: 0,
          class: "inline w-5 h-5 mr-2 text-white"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.badgeCount)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.badgeCount), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})