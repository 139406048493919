<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <div
      v-if="label"
      class="flex justify-between items-center"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
    >
      <BaseLabel
        :for="numberfieldId"
        :is-field-inline="isFieldCustomStyle"
        :label-id="numberfieldId"
        :unit="displayedUnit"
        :icon="labelIcon"
        :icon-tooltip-text="labelIconTooltipText"
        :has-icon-action="hasLabelIconAction"
        :label-icon-class="labelIconClass"
        @icon-action-click="$emit('label-icon-action-click')"
      >
        {{ label }}
      </BaseLabel>

      <BaseAction v-if="actionText" class="text-right" @click="$emit('action-click')">
        {{ actionText }}
      </BaseAction>
    </div>

    <div>
      <input
        :id="numberfieldId"
        class="field flex-1 border border-gray-300 text-xs rounded-lg focus:border-blue-500 focus:ring-0 block w-full px-2 py-1 transition-colors"
        :class="[
          fieldClass,
          {
            highlight: isHighlighted,
            'bg-gray-50 text-gray-400 pointer-events-none': disabled,
            'bg-white hover:border-gray-500 focus:border-blue-600': !disabled,
          },
        ]"
        type="text"
        :placeholder="placeholder"
        v-model="innerValue"
        @focus="handleFocus"
        @blur="handleBlur"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      />
      <p v-if="subText" class="mt-1 text-gray-500">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

const props = defineProps<{
  modelValue: number | null
  label?: string
  fieldClass?: string | (string | Record<string, boolean>)[]
  placeholder?: string
  isHighlighted?: boolean
  disabled?: boolean
  actionText?: string
  unit?: string
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'action-click'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { n, t } = useI18n()

const numberfieldId = ref<string>(crypto.randomUUID())

const formatValue = (value: number | null): string | null => {
  const defaultValue = props.placeholder ? null : '-'
  const val = value && value > 100 ? 100 : value && value < 0 ? 0 : value

  return typeof val === 'number' ? n(val, 'rate') : defaultValue
}

const parseValue = (value: string | null): number | null => {
  let parsedValue: number | null = null

  if (typeof value === 'string') {
    parsedValue = Number(value.replace(',', '.').replace(/\s/g, ''))
    parsedValue = parsedValue > 100 ? 100 : parsedValue < 0 ? 0 : parsedValue
  }

  return parsedValue
}

const innerValue = ref<string | null>(formatValue(props.modelValue))
const displayedUnit = computed<string>(() => props.unit ?? t('common.units.percent'))
const isFocused = ref<boolean>(false)

const handleFocus = (): void => {
  emit('focus')
  isFocused.value = true
  innerValue.value = props.modelValue ? String(props.modelValue) : ''
}

const handleBlur = (): void => {
  emit('blur')
  isFocused.value = false
  const newVal = parseValue(innerValue.value)
  emit('update:modelValue', newVal)
  innerValue.value = formatValue(newVal)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = formatValue(value)),
)
</script>
