<template>
  <div>
    <EnumField
      :label="label"
      :label-icon="labelIcon"
      :label-icon-tooltip-text="labelIconTooltipText"
      :has-label-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      :disabled="disabled"
      :multiple="isMultipleEnabled"
      :field-class="fieldClass"
      :placeholder="$t('common.bank_field.bank_placeholder')"
      :options="bankOptions"
      is-searchable
      v-model="innerValue"
      @focus="emit('focus')"
      @icon-action-click="$emit('label-icon-action-click')"
    />
  </div>
</template>

<script setup lang="ts">
import EnumField from './EnumField.vue'

import banks from '@/config/banks.json'
import { computed, ref, watch } from 'vue'

import type { Component } from 'vue'
import { EFieldKind } from '@/types/FieldKind.enum'

const props = defineProps<{
  modelValue: string | string[] | null
  label?: string
  fieldClass?: string | (string | Record<string, boolean>)[]
  disabled?: boolean
  kind?: EFieldKind
  toggleValue?: boolean
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | string[]): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const innerValue = ref<typeof props.modelValue>(props.modelValue)

const isMultipleEnabled = computed<boolean>(() => props.kind === EFieldKind.MultipleBankGroup)

const bankOptions = computed<Record<string, string>>(() => {
  let bankList: typeof banks = banks

  if (props.kind === EFieldKind.SimpleBankGroupClient) {
    bankList = banks.filter((bank) => bank.has_client_accounts ?? true)
  }

  return bankList.reduce<Record<string, string>>((list, bank) => {
    list[bank.value] = bank.label

    return list
  }, {})
})

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => {
    if (value) {
      emit('update:modelValue', value)
    }

    emit('blur')
  },
  { deep: true },
)
</script>
