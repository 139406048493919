import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-white" }
const _hoisted_2 = { class: "font-bold text-sm" }
const _hoisted_3 = { class: "font-medium text-sm leading-6" }

import { useFieldHelpers } from '@/utils/composables/field.helpers'


export default /*@__PURE__*/_defineComponent({
  __name: 'BankRequestToast',
  props: {
    title: {},
    errors: {}
  },
  setup(__props: any) {



const { goToField } = useFieldHelpers()
// TODO: add  `@click="goToField(error.path)"` to the li element when path is good in bankRequestValidation

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
        return (_openBlock(), _createElementBlock("li", {
          key: error.path
        }, _toDisplayString(error.message), 1))
      }), 128))
    ])
  ]))
}
}

})