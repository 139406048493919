import dayjs from 'dayjs'
import i18n from '../i18n'
import parsePhoneNumber, { NumberFormat } from 'libphonenumber-js'
import { useCountryHelper } from './country.helpers'

import IAddress from '@/types/Address.interface'
import IIncome from '@/types/Income.interface'
import IExtraIncome from '@/types/ExtraIncome.interface'

const { n, t } = i18n.global

const formatPhoneNumber = (value: string, format?: NumberFormat): string => {
  const parsedPhoneNumber = parsePhoneNumber(value, 'FR')
  let phoneNumber: string = value

  if (parsedPhoneNumber && format) {
    phoneNumber = parsedPhoneNumber?.format(format)
  } else if (parsedPhoneNumber?.country === 'FR') {
    phoneNumber = parsedPhoneNumber.formatNational()
  } else if (parsedPhoneNumber) {
    phoneNumber = parsedPhoneNumber.formatInternational()
  }

  return phoneNumber
}

const { getCountryName } = useCountryHelper()

const formatAddress = (address: IAddress): string => {
  return `${address.street ?? ''} ${address.zipcode ?? ''} ${address.city ?? ''}${
    address.country && address.country !== 'fr' ? `, ${getCountryName(address.country.trim())}` : ''
  }`.trim()
}

const formatCity = (value: IAddress, isCodeNeeded = true): string => {
  const { city, zipcode } = value
  let formattedCity = city ?? ''

  if (isCodeNeeded && zipcode?.slice(0, 2) === '75') {
    formattedCity += ` ${Number(zipcode.slice(3, 5)).toString()}`
  }

  return formattedCity
}

// Source: https://stackoverflow.com/a/71734086
const formatTextWithLink = (text: string): string => {
  const isValidHttpUrl = (string: string) => {
    try {
      return new URL(string).protocol.startsWith('http')
    } catch (_) {
      return false
    }
  }

  const emailRegex = /.*@.*/

  const matches = text.match(/(?<=\s|^)[a-zA-Z0-9-:/]+\.[a-zA-Z0-9-].+?(?=[.,;:?!-]?(?:\s|$))/g)

  if (!matches) return text

  const textParts: string[] = []
  let textRest = text

  matches.forEach((x) => {
    const [t1, ...t2] = textRest.split(x)
    textParts.push(t1)
    textRest = t2.join(x)

    if (x.match(emailRegex)) {
      textParts.push('<a href="mailto:' + x + '">' + x + '</a>')
    } else {
      const y = (!x.match(/:\/\//) ? 'https://' : '') + x

      if (isValidHttpUrl(y)) {
        textParts.push('<a href="' + y + '" target="_blank">' + y + '</a>')
      } else {
        textParts.push(x)
      }
    }
  })

  textParts.push(textRest)

  return textParts.join('')
}

const formatDocSize = (size: number, minimumFractionDigits = 1): string => {
  let docSize = size
  let unit = 'byte'

  if (size > 1e6) {
    docSize = size / 1e6
    unit = 'megabyte'
  } else if (size > 1e3) {
    docSize = size / 1e3
    unit = 'kilobyte'
  }

  return n(docSize, {
    style: 'unit',
    unit,
    maximumFractionDigits: 1,
    minimumFractionDigits,
  })
}

interface IFormatDatetimeDefaultOptions {
  format?: string
  parsingFormat?: string[]
}

const formatDatetimeDefaultOptions: IFormatDatetimeDefaultOptions = {
  format: 'DD/MM/YYYY [à] HH:mm',
  parsingFormat: undefined,
}

const formatDatetime = (datetime: string | number | Date, options?: IFormatDatetimeDefaultOptions): string => {
  const { format, parsingFormat } = {
    ...formatDatetimeDefaultOptions,
    ...options,
  }

  return dayjs(datetime, typeof datetime === 'string' ? parsingFormat : undefined).format(format)
}

const formatIncome = (income: IIncome): string =>
  `${income.amount ? n(income.amount, 'currency') : ''} ${income.taxes ? t(`common.income_field.taxes_options.${income.taxes}`) : ''} ${income.period ? t(`common.income_field.period_options.${income.period}`) : ''}`.trim()

const formatExtraIncome = (income: IExtraIncome): string =>
  `${income.amount ? n(income.amount, 'currency') : ''} ${income.period ? t(`common.extra_income_field.period_options.${income.period}`) : ''} ${income.kind ? t(`common.extra_income_field.kind_options.${income.kind}`) : ''}`.trim()

export const useFormatHelper = () => ({
  formatAddress,
  formatCity,
  formatDatetime,
  formatDocSize,
  formatExtraIncome,
  formatIncome,
  formatPhoneNumber,
  formatTextWithLink,
})
