<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :is-field-inline="isFieldCustomStyle"
      :disabled="disabled"
      :has-error="hasError"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
      @click="focus()"
    >
      {{ label }}
    </BaseLabel>

    <div
      ref="countryfieldRef"
      class="relative"
      @mouseenter="fieldHoverHelper?.handleMouseEnter"
      @mouseleave="fieldHoverHelper?.handleMouseLeave"
    >
      <div
        class="field flex border border-gray-300 text-xs rounded-lg cursor-pointer select-none transition-colors px-2 py-1.75"
        :class="[
          fieldClass,
          {
            'border-blue-500 hover:border-blue-600': isFocused,
            'border-b-0 rounded-b-none': isFocused && results.length > 0,
            'bg-gray-50 text-gray-400 pointer-events-none': disabled,
            'bg-white hover:border-gray-500': !disabled && !hasError,
            'border-red-300 bg-red-50 focus:border-red-500': hasError,
          },
        ]"
        :title="displayedValue"
      >
        <p class="flex-1 pr-6 text-xs truncate min-h-[18px]" v-if="!isFocused" @click="focus(!isFocused)">
          {{ displayedValue }}
        </p>

        <input
          v-show="isFocused"
          ref="countryInputRef"
          class="flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none"
          v-model="searchValue"
        />

        <ChevronDownIcon
          class="w-5 h-5 text-gray-500 absolute right-3 inset-y-0 my-auto transition-transform"
          :class="{ 'rotate-180': isFocused && results.length > 0 }"
          @click="focus(!isFocused)"
        />
      </div>

      <div
        v-show="results.length"
        class="absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg overflow-hidden"
        :class="{
          hidden: !isFocused,
          'border-blue-500 border-t-gray-300': isFocused,
        }"
      >
        <ul class="max-h-40 overflow-y-auto">
          <li
            v-for="result in results"
            :key="result.value"
            class="px-2 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors"
            :class="{ 'font-bold': isSelected(result.value) }"
            @click="handleChange(result)"
          >
            {{ result.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

import { computed, inject, nextTick, ref, watch } from 'vue'
import { countrySearch } from '@pretto/places'
import prettoPlacesConfig from '@/config/pretto_places.json'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { useCountryHelper } from '@/utils/composables/country.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

const countriesApi = countrySearch.init(prettoPlacesConfig.countriesAppId, prettoPlacesConfig.countriesApiKey)

interface CountriesSearchResult {
  value: string
  label: string
}

const props = defineProps<{
  modelValue: string | null
  label?: string
  fieldClass?: string | Record<string, boolean>
  disabled?: boolean
  hasError?: boolean
  leftIcon?: Component
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { getCountryName } = useCountryHelper()

const countryfieldRef = ref<HTMLElement | null>(null)
const countryInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<string | null>(props.modelValue)
const isFocused = ref<boolean>(false)
const searchValue = ref<string>(getCountryName((innerValue.value ?? '').trim()))
const results = ref<CountriesSearchResult[]>([])

const displayedValue = computed(() => {
  return getCountryName((innerValue.value ?? '').trim())
})

const focus = async (isFocus = true): Promise<void> => {
  isFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (countryInputRef.value) {
      await nextTick()
      countryInputRef.value.focus()
      await getResults()
    }
  } else {
    emit('blur')
  }
}

const handleChange = (result: CountriesSearchResult): void => {
  innerValue.value = result.value
  searchValue.value = result.label
  isFocused.value = false
  emit('blur')
}

const isSelected = (value: string): boolean => {
  return innerValue.value === value
}

const getResults = async (): Promise<void> => {
  try {
    if (typeof countriesApi !== 'string') {
      const response = await countriesApi.get(searchValue.value.trim(), { limit: 10 })
      results.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}

onClickOutside(countryfieldRef, () => focus(false))

watch(() => searchValue.value, getResults)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>
