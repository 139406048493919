<template>
  <label
    class="items-center gap-x-2 font-semibold"
    :class="{
      'text-gray-400': disabled,
      'cursor-pointer': !disabled,
      'flex @lg/form:justify-between w-full min-h-[34px]': isFieldInline,
      'inline-flex': !isFieldInline,
    }"
  >
    <slot />

    <template v-if="unit">{{ $t('common.base_label.unit', { unit }) }}</template>

    <Component
      v-if="icon"
      :is="hasIconAction ? 'button' : 'span'"
      :class="[
        iconClass,
        {
          'text-blue-600': hasIconAction && !iconClass,
          'text-gray-500': !hasIconAction && !iconClass,
        },
      ]"
      :id="iconTooltipText ? `trigger-${tooltipId}` : undefined"
      :data-tooltip-target="iconTooltipText ? `tooltip-${tooltipId}` : undefined"
      @click.stop="handleActionClick"
    >
      <Component :is="icon" class="w-5 h-5" />

      <div
        v-if="iconTooltipText"
        :id="`tooltip-${tooltipId}`"
        role="tooltip"
        class="inline-block absolute invisible z-10 py-2 px-3 text-xs font-medium text-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip bg-gray-700"
      >
        {{ iconTooltipText }}
        <div class="tooltip-arrow" data-popper-arrow />
      </div>
    </Component>
  </label>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import { Tooltip } from 'flowbite'

import type { Component } from 'vue'

const props = defineProps<{
  labelId?: string
  disabled?: boolean
  hasError?: boolean
  icon?: Component
  iconTooltipText?: string
  hasIconAction?: boolean
  iconClass?: string
  unit?: string
  isFieldInline?: boolean
}>()

const emit = defineEmits<{
  (e: 'icon-action-click'): void
}>()

const tooltipId = ref<string>(props.labelId ?? crypto.randomUUID())

const initTooltip = (): void => {
  if (props.icon && props.iconTooltipText && tooltipId.value) {
    const targetEl = document.getElementById(`tooltip-${tooltipId.value}`)
    const triggerEl = document.getElementById(`trigger-${tooltipId.value}`)

    if (targetEl && triggerEl) {
      new Tooltip(targetEl, triggerEl)
    }
  }
}

onMounted(() => {
  initTooltip()
})

const handleActionClick = (): void => {
  if (props.hasIconAction) {
    emit('icon-action-click')
  }
}

watch(
  () => props.iconTooltipText,
  async () => {
    await nextTick()
    initTooltip()
  },
)
</script>
