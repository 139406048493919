import path from 'path'
import { useConstants } from '@/utils/composables/constants'
import { useMutation, useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import ConfirmUploadMutation from '@/schemas/Preparation/ConfirmUpload.mutation.gql'
import DocumentsQuery from '@/schemas/Preparation/Documents.query.gql'
import LastDocNotificationAtQuery from '@/schemas/Preparation/LastDocNotificationAt.query.gql'
import PrioritizeTerseaTreatmentMutation from '@/schemas/Preparation/PrioritizeTerseaTreatment.mutation.gql'
import SendDocsNotificationMutation from '@/schemas/Preparation/SendDocsNotification.mutation.gql'
import UpdateFileMutation from '@/schemas/Preparation/UpdateFile.mutation.gql'
import UpdateOrCreateDocumentMutation from '@/schemas/Preparation/UpdateOrCreateDocument.mutation.gql'

import IConfirmUploadMutation from '@/types/schemas/Preparation/ConfirmUploadMutation.interface'
import IDocument from '@/types/Document.interface'
import IDocumentFile from '@/types/DocumentFile.interface'
import IDocumentsQuery from '@/types/schemas/Preparation/DocumentsQuery.interface'
import ILastDocNotificationAtQuery from '@/types/schemas/Preparation/LastDocNotificationAtQuery.interface'
import IPrioritizeTerseaTreatmentMutation from '@/types/schemas/Preparation/PrioritizeTerseaTreatmentMutation.interface'
import ISendDocsNotificationMutation from '@/types/schemas/Preparation/SendDocsNotificationMutation.interface'
import IUpdateFileMutation from '@/types/schemas/Preparation/UpdateFileMutation.interface'
import IUpdateOrCreateDocumentMutation from '@/types/schemas/Preparation/UpdateOrCreateDocumentMutation.interface'

const { S3_DOCUMENTS } = useConstants()
const { getProjectId } = useServiceHelper()

interface IConfirmUploadProps {
  projectId: string
  key: string
  kind: string
  originalFilename: string
  mortgagor: number | null
  year: number | null
  month: number | null
  bank: string | null
}

const confirmFileUpload = async (
  variables: IConfirmUploadProps,
): Promise<IConfirmUploadMutation['confirmUpload'] | undefined> => {
  const { data } = await useMutation<IConfirmUploadMutation>({
    mutation: ConfirmUploadMutation,
    variables,
  })

  return data?.confirmUpload
}

const getDocuments = async (): Promise<IDocument[]> => {
  const {
    data: { documents },
  } = await useQuery<IDocumentsQuery>({
    query: DocumentsQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return documents
}

const getLastDocNotificationAt = async (): Promise<string> => {
  const { data } = await useQuery<ILastDocNotificationAtQuery>({
    query: LastDocNotificationAtQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.deal.lastDocNotificationAt
}

const priorizeBatches = async (
  batches: string[],
): Promise<IPrioritizeTerseaTreatmentMutation['prioritizeTerseaTreatment'] | undefined> => {
  const { data } = await useMutation<IPrioritizeTerseaTreatmentMutation>({
    mutation: PrioritizeTerseaTreatmentMutation,
    variables: {
      batchIds: batches,
    },
  })

  return data?.prioritizeTerseaTreatment
}

const sendDocsNotification = async (): Promise<ISendDocsNotificationMutation['sendDocsNotification'] | undefined> => {
  const { data } = await useMutation<ISendDocsNotificationMutation>({
    mutation: SendDocsNotificationMutation,
    variables: {
      projectId: getProjectId(),
    },
  })

  return data?.sendDocsNotification
}

// FROM: app-core>uploadFileRemote.ts
const uploadFile = (
  file: File,
  localId: string,
  callbackProgress?: (localId: string, progress: number) => void,
): Promise<string> => {
  const extname = path.extname(file.name)
  const basename = path.basename(file.name, extname)
  const filename = `${basename}-${localId}${extname}`

  // Remove non-ASCII chars from key
  // eslint-disable-next-line no-control-regex
  const key = `uploads/${getProjectId()}/${filename}`.replace(/[^\x00-\x7F]/g, '')
  const request = new XMLHttpRequest()

  return new Promise<string>((resolve, reject) => {
    request.onerror = reject

    request.onreadystatechange = () => {
      if (request.readyState !== XMLHttpRequest.DONE) {
        return
      }

      if (request.status === 200) {
        return resolve(key)
      }

      reject()
    }

    request.upload.onprogress = (event) => {
      callbackProgress?.(localId, Math.min(event.loaded / event.total, 1))
    }

    request.open('PUT', `${S3_DOCUMENTS}${encodeURIComponent(key)}`, true)
    request.setRequestHeader('x-amz-acl', 'bucket-owner-full-control')
    request.send(file)
  })
}

const updateDocument = async (
  doc: Partial<IDocument>,
): Promise<IUpdateOrCreateDocumentMutation['updateOrCreateDoc'] | undefined> => {
  const { data } = await useMutation<IUpdateOrCreateDocumentMutation>({
    mutation: UpdateOrCreateDocumentMutation,
    variables: {
      ...doc,
      projectId: getProjectId(),
    },
  })

  return data?.updateOrCreateDoc
}

const updateFile = async (
  file: IDocumentFile,
  document: IDocument,
): Promise<IUpdateFileMutation['updateFile'] | undefined> => {
  const { data } = await useMutation<IUpdateFileMutation>({
    mutation: UpdateFileMutation,
    variables: {
      id: file.id,
      documentKind: document.kind,
      documentMeta: document.meta,
      status: file.status,
      projectId: getProjectId(),
    },
  })

  return data?.updateFile
}

export const useDocumentService = () => ({
  confirmFileUpload,
  getDocuments,
  getLastDocNotificationAt,
  priorizeBatches,
  sendDocsNotification,
  updateDocument,
  updateFile,
  uploadFile,
})
