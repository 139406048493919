import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-2" }
const _hoisted_2 = { class: "flex flex-col gap-y-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import EnumField from './EnumField.vue'
import NumberField from './NumberField.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { TOptions } from '@/types/Option.type'
import type { Component } from 'vue'
import IExtraIncome from '@/types/ExtraIncome.interface'
import { EExtraIncome } from '@/types/ExtraIncome.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExtraIncomeField',
  props: {
    modelValue: {},
    fieldClass: {},
    placeholder: {},
    disabled: { type: Boolean },
    subText: {},
    options: {},
    linkedPropertyId: {},
    labelIcon: {}
  },
  emits: ["update:modelValue", "update:linkedPropertyId", "blur", "focus"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<IExtraIncome>(props.modelValue)

const { tm } = useI18n()
const kindOptions: Record<string, string> = tm('common.extra_income_field.kind_options')
const periodOptions: Record<string, string> = tm('common.extra_income_field.period_options')

const handlePropertyIdUpdate = (id: string): void => {
  emit('update:linkedPropertyId', id)
}

const isRent = computed<boolean>(
  () =>
    !!innerValue.value.kind &&
    [EExtraIncome.Rent, EExtraIncome.SeasonalRent, EExtraIncome.CommercialRent].includes(innerValue.value.kind),
)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(EnumField, {
      label: _ctx.$t('common.extra_income_field.kind_label'),
      modelValue: innerValue.value.kind,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((innerValue.value.kind) = $event)),
      options: _unref(kindOptions),
      disabled: _ctx.disabled
    }, null, 8, ["label", "modelValue", "options", "disabled"]),
    (_ctx.options && isRent.value)
      ? (_openBlock(), _createBlock(EnumField, {
          key: 0,
          label: _ctx.$t('project_view.mortgagor.immovable_linked_label'),
          "model-value": _ctx.linkedPropertyId ?? null,
          options: _ctx.options,
          "has-error": !_ctx.linkedPropertyId,
          "error-message": _ctx.$t('project_view.mortgagor.immovable_error_message'),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => handlePropertyIdUpdate(value as string))
        }, null, 8, ["label", "model-value", "options", "has-error", "error-message"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
        '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value,
      })
    }, [
      _createVNode(BaseLabel, {
        class: _normalizeClass(["self-start", {
          'mb-1': !isFieldCustomStyle.value,
        }]),
        disabled: _ctx.disabled,
        "is-field-inline": isFieldCustomStyle.value,
        unit: _ctx.$t('common.units.currency'),
        icon: _ctx.labelIcon
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.extra_income_field.amount_label')), 1)
        ]),
        _: 1
      }, 8, ["class", "disabled", "is-field-inline", "unit", "icon"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(NumberField, {
          "field-class": _ctx.fieldClass,
          modelValue: innerValue.value.amount,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((innerValue.value.amount) = $event)),
          disabled: _ctx.disabled
        }, null, 8, ["field-class", "modelValue", "disabled"]),
        _createVNode(EnumField, {
          "field-class": _ctx.fieldClass,
          modelValue: innerValue.value.period,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((innerValue.value.period) = $event)),
          options: _unref(periodOptions),
          disabled: _ctx.disabled
        }, null, 8, ["field-class", "modelValue", "options", "disabled"]),
        (_ctx.subText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subText), 1))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}
}

})