<template>
  <button
    type="button"
    class="text-blue-600 hover:text-blue-700 transition-colors"
    :disabled="isButtonDisabled"
    @click="handleClick"
  >
    <SpinnerIcon v-if="isLoading" class="inline w-5 h-5 mr-2" />

    <slot />
  </button>
</template>

<script setup lang="ts">
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { computed } from 'vue'
import { useAnalytics } from '@/utils/composables/segment'

import { IEvent } from '@/utils/tracking'

const props = defineProps<{
  disabled?: boolean
  isLoading?: boolean
  trackObject?: IEvent
  trackProps?: Record<string, string | undefined>
}>()

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()

const isButtonDisabled = computed(() => props.disabled || props.isLoading)

const { trackEvent } = useAnalytics()

const handleClick = (event: Event) => {
  if (props.trackObject) {
    trackEvent(props.trackObject, props.trackProps)
  }

  emit('click', event)
}
</script>
