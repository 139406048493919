import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "disabled"]
const _hoisted_2 = ["for"]

import BaseTooltip from '@/components/base/tooltip/BaseTooltip.vue'

import { inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import { ETooltipPosition } from '@/types/TooltipPosition.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'BoolField',
  props: {
    modelValue: { type: [Boolean, null] },
    label: {},
    disabled: { type: Boolean },
    hasTooltip: { type: Boolean },
    tooltipText: {}
  },
  emits: ["update:modelValue", "blur"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const innerValue = ref<boolean>(!!props.modelValue)
const boolfieldId = ref<string>(crypto.randomUUID())
const boolfieldKey = ref<number>(new Date().getTime())

watch(
  () => props.modelValue,
  (value) => {
    innerValue.value = !!value
    boolfieldKey.value = new Date().getTime()
  },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bool-field inline-flex items-center", {
      'relative group/tooltip': _ctx.hasTooltip,
    }]),
    onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
  }, [
    _withDirectives((_openBlock(), _createElementBlock("input", {
      key: boolfieldKey.value,
      id: boolfieldId.value,
      class: _normalizeClass(["field w-4 h-4 text-blue-600 rounded border-gray-300 bg-gray-50 focus:ring-0 peer", {
        'cursor-pointer': !_ctx.disabled,
      }]),
      type: "checkbox",
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((innerValue).value = $event)),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (emit('blur')))
    }, null, 42, _hoisted_1)), [
      [_vModelCheckbox, innerValue.value]
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: boolfieldId.value,
          class: _normalizeClass(["ml-2 select-none", {
        'cursor-pointer text-gray-500': !_ctx.disabled,
        'text-gray-400': _ctx.disabled,
      }])
        }, _toDisplayString(_ctx.label), 11, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.hasTooltip)
      ? (_openBlock(), _createBlock(BaseTooltip, {
          key: 1,
          position: _unref(ETooltipPosition).Top
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tooltipText), 1)
          ]),
          _: 1
        }, 8, ["position"]))
      : _createCommentVNode("", true)
  ], 34))
}
}

})