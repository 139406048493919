import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "template-income grid gap-2" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "area-subtext col-span-full text-gray-500"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import NumberField from './NumberField.vue'
import EnumField from './EnumField.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Component } from 'vue'
import IIncome from '@/types/Income.interface'


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeField',
  props: {
    modelValue: {},
    disabled: { type: Boolean },
    fieldClass: {},
    hasLabelIconAction: { type: Boolean },
    isDeletable: { type: Boolean },
    label: {},
    labelIcon: {},
    labelIconClass: {},
    labelIconTooltipText: {},
    placeholder: {},
    subText: {},
    unit: {}
  },
  emits: ["label-icon-action-click", "update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { t, tm } = useI18n()

const innerValue = ref<IIncome>(props.modelValue)
const textfieldId = ref<string>(crypto.randomUUID())
const displayedUnit = computed<string>(() => props.unit ?? t('common.units.currency'))

const taxesOptions: Record<string, string> = tm('common.income_field.taxes_options')
const periodOptions: Record<string, string> = tm('common.income_field.period_options')

const deleteIncome = async (): Promise<void> => {
  innerValue.value = {
    amount: null,
    period: null,
    taxes: null,
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          for: textfieldId.value,
          class: _normalizeClass(["self-start", {
        'mb-1': !isFieldCustomStyle.value,
      }]),
          disabled: _ctx.disabled,
          unit: displayedUnit.value,
          "is-field-inline": isFieldCustomStyle.value,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["for", "class", "disabled", "unit", "is-field-inline", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(NumberField, {
        class: "area-field1",
        modelValue: innerValue.value.amount,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue.value.amount) = $event)),
        disabled: _ctx.disabled
      }, null, 8, ["modelValue", "disabled"]),
      _createVNode(EnumField, {
        class: "area-field2",
        modelValue: innerValue.value.taxes,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((innerValue.value.taxes) = $event)),
        options: _unref(taxesOptions),
        disabled: _ctx.disabled
      }, null, 8, ["modelValue", "options", "disabled"]),
      _createVNode(EnumField, {
        class: "area-field3",
        modelValue: innerValue.value.period,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((innerValue.value.period) = $event)),
        options: _unref(periodOptions),
        disabled: _ctx.disabled
      }, null, 8, ["modelValue", "options", "disabled"]),
      (_ctx.isDeletable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: _normalizeClass(["area-button", {
          'text-gray-300': _ctx.disabled,
          'text-gray-500 hover:text-gray-600 transition-colors': !_ctx.disabled,
        }]),
            disabled: _ctx.disabled,
            onClick: deleteIncome
          }, [
            _createVNode(_unref(XMarkIcon), { class: "w-5 h-5" })
          ], 10, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})