import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "relative bg-white rounded-lg shadow" }
const _hoisted_2 = { class: "flex items-center gap-x-2" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "modal-body p-6 space-y-6 overflow-y-auto" }

import BaseButton from '@/components/base/button/BaseButton.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { computed, FunctionalComponent, onMounted, ref, watch } from 'vue'
import { Modal } from 'flowbite'
import { useI18n } from 'vue-i18n'

import { IEvent } from '@/utils/tracking'
import type { ModalInterface } from 'flowbite'
import { EStatusColor } from '@/types/StatusColor.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModal',
  props: {
    isVisible: { type: Boolean },
    title: {},
    closeText: {},
    okText: {},
    modalSize: { default: 'large' },
    isOkDisabled: { type: Boolean },
    hideValidation: { type: Boolean },
    hideFooter: { type: Boolean },
    isLoading: { type: Boolean },
    modalType: {},
    icon: {},
    trackObjectOk: {},
    trackObjectClose: {},
    trackProps: {},
    hideBorders: { type: Boolean }
  },
  emits: ["close", "validate"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n()

const props = __props

const closeText = computed(() => props.closeText ?? t('common.modal.close'))
const okText = computed(() => props.okText ?? t('common.modal.ok'))

const emit = __emit

const modalRef = ref<HTMLElement | null>(null)
const modal = ref<ModalInterface | null>(null)

onMounted(() => {
  if (modalRef.value) {
    modal.value = new Modal(modalRef.value, {
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[59]', // default tailwind classes + different z-index ; TODO: remove tailwind
      backdrop: 'dynamic',
      closable: false,
    })
  }
})

const openModal = () => {
  if (modal.value) {
    modal.value.show()
  }
}

const closeModal = () => {
  if (modal.value) {
    modal.value.hide()

    if (props.isVisible) {
      emit('close')
    }
  }
}

watch(
  () => props.isVisible,
  () => {
    if (props.isVisible) {
      openModal()
    } else {
      closeModal()
    }
  },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      ref_key: "modalRef",
      ref: modalRef,
      tabindex: "-1",
      "aria-hidden": "true",
      class: "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[60] w-full md:inset-0 h-modal md:h-full"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["relative p-4 w-full h-full md:h-auto", {
          'max-w-md': _ctx.modalSize === 'small',
          'max-w-lg': _ctx.modalSize === 'medium',
          'max-w-3xl': _ctx.modalSize === 'default',
          'max-w-5xl': _ctx.modalSize === 'large',
          'max-w-7xl': _ctx.modalSize === 'xlarge',
        }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex justify-between items-start p-4 rounded-t", {
              'border-b': !_ctx.hideBorders,
            }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.icon)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["p-1.5 rounded-lg", {
                  'bg-red-100 text-red-600': _ctx.modalType === _unref(EStatusColor).Danger,
                }])
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-5 h-5" }))
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-colors",
              onClick: closeModal
            }, [
              _createVNode(_unref(XMarkIcon), { class: "w-5 h-5" })
            ])
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (!_ctx.hideFooter)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["flex items-center p-6 space-x-2 rounded-b border-gray-200", { 'justify-center': _ctx.hideValidation, 'justify-between': !_ctx.hideValidation, 'border-t': !_ctx.hideBorders }])
              }, [
                _renderSlot(_ctx.$slots, "modal-footer", {}, () => [
                  _createVNode(BaseButton, {
                    class: "simple",
                    "track-object": _ctx.trackObjectClose ?? _ctx.$trackedEvents.noTracking,
                    "track-props": _ctx.trackProps,
                    onClick: closeModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(closeText.value), 1)
                    ]),
                    _: 1
                  }, 8, ["track-object", "track-props"]),
                  (!_ctx.hideValidation)
                    ? (_openBlock(), _createBlock(BaseButton, {
                        key: 0,
                        class: _normalizeClass([
                  _ctx.modalType,
                  {
                    primary: !_ctx.modalType,
                  },
                ]),
                        "is-loading": _ctx.isLoading,
                        disabled: _ctx.isOkDisabled,
                        "track-object": _ctx.trackObjectOk ?? _ctx.$trackedEvents.noTracking,
                        "track-props": _ctx.trackProps,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('validate')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(okText.value), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "is-loading", "disabled", "track-object", "track-props"]))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 512)
  ]))
}
}

})