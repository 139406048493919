import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_3 = ["data-value"]
const _hoisted_4 = ["id", "placeholder", "spellcheck", "rows"]
const _hoisted_5 = {
  key: 1,
  class: "mt-1 text-gray-500 text-xs"
}
const _hoisted_6 = {
  key: 2,
  class: "mt-1 text-red-600 text-xs"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextareaField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    placeholder: {},
    disabled: { type: Boolean },
    spellcheck: { type: Boolean },
    rows: {},
    hasError: { type: Boolean },
    errorMessage: {},
    required: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    isHeightInfinite: { type: Boolean },
    isLoading: { type: Boolean },
    subText: {}
  },
  emits: ["update:modelValue", "input", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const innerValue = ref<string>(props.modelValue ?? '')
const textareafieldId = ref<string>(crypto.randomUUID())

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? ''),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          for: textareafieldId.value,
          class: "mb-1",
          disabled: _ctx.disabled,
          "has-error": _ctx.hasError,
          "label-id": textareafieldId.value,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["for", "disabled", "has-error", "label-id", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["grid relative", {
        'area-content after:block after:invisible after:p-4 after:text-xs after:whitespace-pre-wrap after:leading-6':
          _ctx.isHeightInfinite,
      }]),
      "data-value": innerValue.value
    }, [
      _withDirectives(_createElementVNode("textarea", {
        id: textareafieldId.value,
        class: _normalizeClass(["field border border-gray-300 text-xs rounded-lg focus:ring-0 focus:border-blue-600 block w-full p-4 transition-colors", [
          _ctx.fieldClass,
          {
            'bg-white hover:border-gray-500  focus:hover:border-blue-600': !_ctx.disabled && !_ctx.hasError,
            'bg-white text-gray-400 pointer-events-none': _ctx.disabled,
            'min-h-[250px]': !_ctx.rows,
            'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
            'resize-none overflow-hidden': _ctx.isHeightInfinite,
          },
        ]]),
        placeholder: _ctx.placeholder,
        spellcheck: _ctx.spellcheck,
        rows: _ctx.rows,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue).value = $event)),
        onInput: _cache[2] || (_cache[2] = ($event: any) => (emit('input'))),
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (emit('focus'))),
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (emit('blur'))),
        onMouseenter: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, null, 42, _hoisted_4), [
        [_vModelText, innerValue.value]
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(SpinnerIcon, {
            key: 0,
            class: "absolute inset-y-0 my-auto left-2 w-5 h-5 text-gray-400"
          }))
        : _createCommentVNode("", true)
    ], 10, _hoisted_3),
    (_ctx.subText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.subText), 1))
      : _createCommentVNode("", true),
    (_ctx.hasError && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})