<template>
  <div
    class="relative"
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <div
      class="flex justify-between items-center"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
    >
      <BaseLabel v-if="label" :is-field-inline="isFieldCustomStyle">
        {{ label }}
      </BaseLabel>

      <slot name="label-right" />
    </div>

    <div class="flex">
      <div v-for="(value, key, index) in options" :key="key" class="flex-1 flex text-center">
        <input
          :id="`${segmentedbarfieldId}-${key}`"
          type="radio"
          :value="key"
          v-model="innerValue"
          :name="segmentedbarfieldId"
          class="hidden peer"
        />

        <label
          :for="`${segmentedbarfieldId}-${key}`"
          class="flex-1 py-2 px-4 font-semibold bg-white cursor-pointer border-y border-r border-gray-200 transition-colors hover:bg-gray-50 peer-checked:text-blue-600 peer-checked:bg-blue-50"
          :class="[
            optionClasses,
            {
              'rounded-l-lg border-l': index === 0,
              'rounded-r-lg': index === Object.keys(options).length - 1,
            },
          ]"
        >
          {{ value }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useAnalytics } from '@/utils/composables/segment'

import { IEvent } from '@/utils/tracking'

const props = defineProps<{
  modelValue: string | null
  options: Record<string, string>
  label?: string
  optionClasses?: string
  trackObject: IEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void
  (e: 'blur'): void
}>()

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<typeof props.modelValue>(props.modelValue)
const segmentedbarfieldId = ref<string>(crypto.randomUUID())

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

const { trackEvent } = useAnalytics()

watch(
  () => innerValue.value,
  (value) => {
    trackEvent(props.trackObject, {
      value: innerValue.value ? innerValue.value : undefined,
    })

    emit('update:modelValue', value)
  },
)
</script>
