import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id", "min", "max", "disabled"]

import BaseButton from '@/components/base/button/BaseButton.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { MinusIcon, PlusIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'IncrementField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    min: { default: 0 },
    max: { default: 10 },
    disabled: { type: Boolean },
    unit: {},
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {}
  },
  emits: ["update:modelValue", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const incrementfieldId = ref<string>(crypto.randomUUID())

const innerValue = ref<number>(props.modelValue ?? 0)

const increment = (): void => {
  if (innerValue.value < props.max) {
    innerValue.value++
  }
}

const decrement = (): void => {
  if (innerValue.value > props.min) {
    innerValue.value--
  }
}

watch(innerValue, (value) => emit('update:modelValue', value))

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? 0),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex justify-between items-center", {
        'mb-1': !isFieldCustomStyle.value,
      }])
        }, [
          _createVNode(BaseLabel, {
            for: incrementfieldId.value,
            "is-field-inline": isFieldCustomStyle.value,
            "label-id": incrementfieldId.value,
            unit: _ctx.unit,
            icon: _ctx.labelIcon,
            "icon-tooltip-text": _ctx.labelIconTooltipText,
            "has-icon-action": _ctx.hasLabelIconAction,
            "label-icon-class": _ctx.labelIconClass,
            onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["for", "is-field-inline", "label-id", "unit", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center gap-x-2", {
        'justify-self-end': isFieldCustomStyle.value,
      }]),
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
    }, [
      _createVNode(BaseButton, {
        class: "simple icon flex-none",
        "track-object": _ctx.$trackedEvents.noTracking,
        onClick: decrement,
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(MinusIcon), { class: "w-5 h-5" })
        ]),
        _: 1
      }, 8, ["track-object", "disabled"]),
      _withDirectives(_createElementVNode("input", {
        id: incrementfieldId.value,
        class: _normalizeClass(["field text-xs border-0 focus:ring-0 block w-6 p-0 text-center", [
          _ctx.fieldClass,
          {
            'text-gray-400 pointer-events-none': _ctx.disabled,
          },
        ]]),
        type: "text",
        inputmode: "numeric",
        min: _ctx.min,
        max: _ctx.max,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue).value = $event))
      }, null, 10, _hoisted_1), [
        [_vModelText, innerValue.value]
      ]),
      _createVNode(BaseButton, {
        class: "simple icon flex-none",
        "track-object": _ctx.$trackedEvents.noTracking,
        onClick: increment,
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(PlusIcon), { class: "w-5 h-5" })
        ]),
        _: 1
      }, 8, ["track-object", "disabled"])
    ], 34)
  ], 2))
}
}

})