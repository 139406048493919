import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-popper-target", "data-popper-placement"]

import { ETooltipPosition } from '@/types/TooltipPosition.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTooltip',
  props: {
    position: {},
    arrowClass: {},
    popperPlacement: {},
    tooltipClass: {},
    isTooltipVisible: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-block absolute z-[10000] py-1 px-2 text-xs font-medium text-white text-center bg-gray-900 rounded-lg shadow-sm tooltip w-max h-max max-w-[250px] mb-2 group-hover/tooltip:visible group-hover/tooltip:opacity-100 transition-opacity whitespace-pre-line", [
      _ctx.tooltipClass,
      {
        'bottom-full left-1/2 -translate-x-1/2': _ctx.position === 'top',
        'top-full left-1/2 -translate-x-1/2': _ctx.position === 'bottom',
        'mb-2': _ctx.popperPlacement === 'top',
        'mt-2': _ctx.popperPlacement === 'bottom',
        'visible opacity-100': _ctx.isTooltipVisible,
        'invisible opacity-0': !_ctx.isTooltipVisible,
      },
    ]]),
    "data-popper-target": `tooltip-${_ctx.popperPlacement ?? _ctx.position}`,
    "data-popper-placement": _ctx.popperPlacement ?? _ctx.position
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", {
      class: _normalizeClass(["tooltip-arrow group-hover/tooltip:before:!visible before:opacity-0 group-hover/tooltip:before:opacity-100 transition-opacity", [
        _ctx.arrowClass,
        {
          'bottom-full left-1/2 -translate-x-1/2': _ctx.position === 'top',
          'top-full left-1/2 -translate-x-1/2': _ctx.position === 'bottom',
          'before:!visible before:opacity-100': _ctx.isTooltipVisible,
          'before:opacity-0': !_ctx.isTooltipVisible,
        },
      ]])
    }, null, 2)
  ], 10, _hoisted_1))
}
}

})