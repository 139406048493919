<template>
  <div
    class="bool-field inline-flex items-center"
    :class="{
      'relative group/tooltip': hasTooltip,
    }"
    @mouseenter="fieldHoverHelper?.handleMouseEnter"
    @mouseleave="fieldHoverHelper?.handleMouseLeave"
  >
    <input
      :key="boolfieldKey"
      :id="boolfieldId"
      class="field w-4 h-4 text-blue-600 rounded border-gray-300 bg-gray-50 focus:ring-0 peer"
      :class="{
        'cursor-pointer': !disabled,
      }"
      type="checkbox"
      :disabled="disabled"
      v-model="innerValue"
      @blur="emit('blur')"
    />

    <label
      v-if="label"
      :for="boolfieldId"
      class="ml-2 select-none"
      :class="{
        'cursor-pointer text-gray-500': !disabled,
        'text-gray-400': disabled,
      }"
    >
      {{ label }}
    </label>

    <BaseTooltip v-if="hasTooltip" :position="ETooltipPosition.Top">
      {{ tooltipText }}
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import BaseTooltip from '@/components/base/tooltip/BaseTooltip.vue'

import { inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import { ETooltipPosition } from '@/types/TooltipPosition.enum'

const props = defineProps<{
  modelValue: boolean | null
  label?: string
  disabled?: boolean
  hasTooltip?: boolean
  tooltipText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'blur'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const innerValue = ref<boolean>(!!props.modelValue)
const boolfieldId = ref<string>(crypto.randomUUID())
const boolfieldKey = ref<number>(new Date().getTime())

watch(
  () => props.modelValue,
  (value) => {
    innerValue.value = !!value
    boolfieldKey.value = new Date().getTime()
  },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>
