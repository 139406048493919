<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      class="self-start"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :unit="$t('common.units.currency')"
      :disabled="disabled"
      :is-field-inline="isFieldCustomStyle"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div class="template-income grid gap-2">
      <NumberField class="area-field1" :field-class="fieldClass" v-model="innerValue.amount" :disabled="disabled" />

      <EnumField
        class="area-field2"
        :field-class="fieldClass"
        v-model="innerValue.period"
        :options="periodOptions"
        :disabled="disabled"
      />

      <button
        type="button"
        class="area-button"
        :class="{
          'text-gray-300': disabled,
          'text-gray-500 hover:text-gray-600 transition-colors': !disabled,
        }"
        :disabled="disabled"
        @click="deleteIncome"
      >
        <XMarkIcon class="w-5 h-5" />
      </button>

      <p v-if="subText" class="text-gray-500">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import EnumField from './EnumField.vue'
import NumberField from './NumberField.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { trackingKey } from '@/utils/tracking'
import { useAnalytics } from '@/utils/composables/segment'
import { useI18n } from 'vue-i18n'

import type { Component } from 'vue'
import { TOptions } from '@/types/Option.type'
import IExtraIncome from '@/types/ExtraIncome.interface'

const props = defineProps<{
  modelValue: IExtraIncome
  disabled?: boolean
  fieldClass?: string | (string | Record<string, boolean>)[]
  hasLabelIconAction?: boolean
  label?: string
  labelIcon?: Component
  labelIconClass?: string
  labelIconTooltipText?: string
  options?: TOptions
  placeholder?: string
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
  (e: 'update:modelValue', value: IExtraIncome): void
}>()

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<IExtraIncome>(props.modelValue)

const { tm } = useI18n()
const periodOptions: Record<string, string> = tm('common.extra_income_field.period_options')

const { trackEvent } = useAnalytics()
const trackedEvents = inject(trackingKey)

const deleteIncome = (): void => {
  if (trackedEvents) {
    trackEvent(trackedEvents.projectImmovablesIncomeDelete)
  }

  innerValue.value._delete = true
  emit('blur')
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)
</script>

<style scoped>
.template-income {
  grid-template-areas:
    'field1 button'
    'field2 .'
    'subtext .';
  grid-template-columns: 1fr auto;
}

.area-field1 {
  grid-area: field1;
}

.area-field2 {
  grid-area: field2;
}

.area-button {
  grid-area: button;
}

.area-subtext {
  grid-area: subtext;
}

@container form (min-width: 32rem) {
  .template-income {
    grid-template-areas:
      'field1 field2 button'
      'subtext subtext subtext';
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
