import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, withModifiers as _withModifiers, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "placeholder", "min", "max"]

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { computed, inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    placeholder: {},
    disabled: { type: Boolean },
    type: { default: 'text' },
    min: {},
    max: {},
    hasError: { type: Boolean },
    leftIcon: {},
    isLoading: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    fieldIcon: {},
    forceFieldInline: { type: Boolean }
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => props.forceFieldInline || isFieldInline)

const innerValue = ref<string>(props.modelValue ?? '')
const textfieldId = ref<string>(crypto.randomUUID())
const isFocused = ref<boolean>(false)
const inputRef = ref<HTMLInputElement | null>(null)

const handleFocus = (): void => {
  isFocused.value = true
  emit('focus')
}

const handleBlur = (): void => {
  isFocused.value = false
  emit('blur')
}

const forceFocus = (): void => {
  if (inputRef.value) {
    inputRef.value.focus()
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? ''),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
      grid: isFieldCustomStyle.value && _ctx.label && _ctx.forceFieldInline,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          for: textfieldId.value,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          "is-field-inline": isFieldCustomStyle.value,
          disabled: _ctx.disabled,
          "has-error": _ctx.hasError,
          "label-id": textfieldId.value,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["for", "class", "is-field-inline", "disabled", "has-error", "label-id", "icon", "icon-tooltip-text", "has-icon-action", "icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["field border border-gray-300 rounded-lg flex items-center px-2 py-1 cursor-text transition-colors", [
        _ctx.fieldClass,
        {
          'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
          'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
          'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
          'border-blue-600 hover:border-blue-600': isFocused.value,
        },
      ]]),
      onClick: _withModifiers(forceFocus, ["self"]),
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
    }, [
      _createElementVNode("label", { for: textfieldId.value }, [
        (_ctx.fieldIcon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.fieldIcon), {
              key: 0,
              class: _normalizeClass(["w-5 h-5 mr-2", {
            'text-gray-400': _ctx.disabled,
            'text-gray-500': !_ctx.disabled,
          }])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1),
      _withDirectives(_createElementVNode("input", {
        ref_key: "inputRef",
        ref: inputRef,
        id: textfieldId.value,
        class: "border-0 p-0 w-full text-xs bg-transparent focus:ring-0",
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        min: _ctx.min,
        max: _ctx.max,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue).value = $event)),
        onFocus: handleFocus,
        onBlur: handleBlur
      }, null, 40, _hoisted_2), [
        [_vModelDynamic, innerValue.value]
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(SpinnerIcon, {
            key: 0,
            class: "w-5 h-5 text-blue-700"
          }))
        : _createCommentVNode("", true)
    ], 34)
  ], 2))
}
}

})