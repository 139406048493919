<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
      grid: isFieldCustomStyle && label && forceFieldInline,
    }"
  >
    <BaseLabel
      v-if="label"
      :for="textfieldId"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :is-field-inline="isFieldCustomStyle"
      :disabled="disabled"
      :has-error="hasError"
      :label-id="textfieldId"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div
      class="field border border-gray-300 rounded-lg flex items-center px-2 py-1 cursor-text transition-colors"
      :class="[
        fieldClass,
        {
          'bg-white hover:border-gray-500': !disabled && !hasError,
          'bg-gray-50 text-gray-400 pointer-events-none': disabled,
          'border-red-300 bg-red-50 focus:border-red-500': hasError,
          'border-blue-600 hover:border-blue-600': isFocused,
        },
      ]"
      @click.self="forceFocus"
      @mouseenter="fieldHoverHelper?.handleMouseEnter"
      @mouseleave="fieldHoverHelper?.handleMouseLeave"
    >
      <label :for="textfieldId">
        <component
          v-if="fieldIcon"
          :is="fieldIcon"
          class="w-5 h-5 mr-2"
          :class="{
            'text-gray-400': disabled,
            'text-gray-500': !disabled,
          }"
        />
      </label>

      <input
        ref="inputRef"
        :id="textfieldId"
        class="border-0 p-0 w-full text-xs bg-transparent focus:ring-0"
        :type="type"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        v-model="innerValue"
        @focus="handleFocus"
        @blur="handleBlur"
      />

      <SpinnerIcon class="w-5 h-5 text-blue-700" v-if="isLoading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { computed, inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: string | null
    label?: string
    fieldClass?: string | (string | Record<string, boolean>)[]
    placeholder?: string
    disabled?: boolean
    type?: string
    min?: number
    max?: number
    hasError?: boolean
    leftIcon?: Component
    isLoading?: boolean
    labelIcon?: Component
    labelIconTooltipText?: string
    hasLabelIconAction?: boolean
    labelIconClass?: string
    fieldIcon?: Component
    forceFieldInline?: boolean
  }>(),
  {
    type: 'text',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => props.forceFieldInline || isFieldInline)

const innerValue = ref<string>(props.modelValue ?? '')
const textfieldId = ref<string>(crypto.randomUUID())
const isFocused = ref<boolean>(false)
const inputRef = ref<HTMLInputElement | null>(null)

const handleFocus = (): void => {
  isFocused.value = true
  emit('focus')
}

const handleBlur = (): void => {
  isFocused.value = false
  emit('blur')
}

const forceFocus = (): void => {
  if (inputRef.value) {
    inputRef.value.focus()
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? ''),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>
