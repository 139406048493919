/* eslint-disable @typescript-eslint/no-explicit-any */
import project_schema from '@/config/project_schema.json'
import { get, set, merge, omit } from 'lodash'

function buildSchema(obj: any): Record<string, any> {
  let res = {}
  if (!obj.fields && !get(obj, 'of.fields')) return obj.of || obj

  if (obj.fields) {
    res = merge(res, omit(obj, 'fields'))
    obj.fields.forEach((field: any) => {
      set(res, field.name, buildSchema(field))
    })

    return res
  }

  res = merge(res, omit(obj.of, 'fields'))
  obj.of.fields.forEach((field: any) => {
    set(res, field.name, buildSchema(field))
  })

  return res
}

export default buildSchema(project_schema)
