import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]

import { nextTick, onMounted, ref, watch } from 'vue'
import { Tooltip } from 'flowbite'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLabel',
  props: {
    labelId: {},
    disabled: { type: Boolean },
    hasError: { type: Boolean },
    icon: {},
    iconTooltipText: {},
    hasIconAction: { type: Boolean },
    iconClass: {},
    unit: {},
    isFieldInline: { type: Boolean }
  },
  emits: ["icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const tooltipId = ref<string>(props.labelId ?? crypto.randomUUID())

const initTooltip = (): void => {
  if (props.icon && props.iconTooltipText && tooltipId.value) {
    const targetEl = document.getElementById(`tooltip-${tooltipId.value}`)
    const triggerEl = document.getElementById(`trigger-${tooltipId.value}`)

    if (targetEl && triggerEl) {
      new Tooltip(targetEl, triggerEl)
    }
  }
}

onMounted(() => {
  initTooltip()
})

const handleActionClick = (): void => {
  if (props.hasIconAction) {
    emit('icon-action-click')
  }
}

watch(
  () => props.iconTooltipText,
  async () => {
    await nextTick()
    initTooltip()
  },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["items-center gap-x-2 font-semibold", {
      'text-gray-400': _ctx.disabled,
      'cursor-pointer': !_ctx.disabled,
      'flex @lg/form:justify-between w-full min-h-[34px]': _ctx.isFieldInline,
      'inline-flex': !_ctx.isFieldInline,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.unit)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('common.base_label.unit', { unit: _ctx.unit })), 1)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasIconAction ? 'button' : 'span'), {
          key: 1,
          class: _normalizeClass([
        _ctx.iconClass,
        {
          'text-blue-600': _ctx.hasIconAction && !_ctx.iconClass,
          'text-gray-500': !_ctx.hasIconAction && !_ctx.iconClass,
        },
      ]),
          id: _ctx.iconTooltipText ? `trigger-${tooltipId.value}` : undefined,
          "data-tooltip-target": _ctx.iconTooltipText ? `tooltip-${tooltipId.value}` : undefined,
          onClick: _withModifiers(handleActionClick, ["stop"])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-5 h-5" })),
            (_ctx.iconTooltipText)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: `tooltip-${tooltipId.value}`,
                  role: "tooltip",
                  class: "inline-block absolute invisible z-10 py-2 px-3 text-xs font-medium text-white rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip bg-gray-700"
                }, [
                  _createTextVNode(_toDisplayString(_ctx.iconTooltipText) + " ", 1),
                  _cache[0] || (_cache[0] = _createElementVNode("div", {
                    class: "tooltip-arrow",
                    "data-popper-arrow": ""
                  }, null, -1))
                ], 8, _hoisted_1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "id", "data-tooltip-target"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})