import countries from 'i18n-iso-countries'
import countriesFr from 'i18n-iso-countries/langs/fr.json'

countries.registerLocale(countriesFr)

const getCountryName = (countryCode: string): string => {
  return countries.getName(countryCode, 'fr') ?? ''
}

export const useCountryHelper = () => ({
  getCountryName,
})
