<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :is-field-inline="isFieldCustomStyle"
      :disabled="disabled"
      :has-error="hasError"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @click="focus()"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div>
      <div
        class="flex"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      >
        <div class="flex-shrink relative" ref="countryRef">
          <div
            class="field border border-gray-300 text-xs rounded-lg rounded-r-none flex cursor-pointer select-none transition-colors px-2"
            :class="[
              fieldClass,
              {
                'border-blue-600 hover:border-blue-600 py-1.75': isCountryFocused,
                'py-1': !isCountryFocused,
                'border-b-gray-300 rounded-b-none': isCountryFocused && countryResults.length > 0,
                'border-r-0': isCityFocused,
                'bg-gray-50 text-gray-400 pointer-events-none': disabled,
                'border-red-300 bg-red-50 focus:border-red-500': hasError,
                'bg-white hover:border-gray-500': !disabled && !hasError,
              },
            ]"
          >
            <p
              v-if="!isCountryFocused"
              class="pr-7 text-base truncate min-h-[16px]"
              @click="focusCountryField(!isCountryFocused)"
            >
              <span class="fi" :class="[`fi-${innerValue.country}`]" />
            </p>

            <input
              v-show="isCountryFocused"
              ref="countryInputRef"
              id="country-search"
              class="flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none w-full"
              v-model="countrySearchValue"
            />

            <ChevronDownIcon
              class="w-5 h-5 text-gray-500 absolute right-2 inset-y-0 my-auto transition-transform"
              :class="{ 'rotate-180': isCountryFocused && countryResults.length > 0 }"
              @click="focusCountryField(!isCountryFocused)"
            />
          </div>

          <div
            v-show="countryResults.length"
            class="absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 border-t-0 rounded-b-lg overflow-hidden"
            :class="{
              hidden: !isCountryFocused,
              'border-blue-600 border-t-gray-300': isCountryFocused,
            }"
          >
            <ul class="max-h-40 overflow-y-auto">
              <li
                v-for="result in countryResults"
                :key="result.label"
                class="px-2 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors"
                @click="handleCountryChange(result)"
              >
                <span class="mr-1 fi" :class="[`fi-${result.value}`]" />
                {{ result.label }}
              </li>
            </ul>
          </div>
        </div>

        <div class="flex-1 relative" ref="cityRef">
          <div
            class="field flex border border-gray-300 text-xs rounded-lg rounded-l-none cursor-text transition-colors px-2 py-1.75"
            :class="[
              fieldClass,
              {
                'border-blue-600 hover:border-blue-600': isCityFocused,
                'border-b-gray-300 rounded-b-none': isCityFocused && cityResults.length > 0,
                'border-l-0': !isCityFocused,
                'bg-gray-50 pointer-events-none': disabled,
                'bg-white hover:border-gray-500': !disabled && !hasError,
                'border-red-300 bg-red-50 focus:border-red-500': hasError,
              },
            ]"
          >
            <p class="flex-1 text-xs truncate min-h-[18px]" v-if="!isCityFocused" @click="focus(!isCityFocused)">
              {{ displayedCity }}
            </p>

            <input
              v-show="isCityFocused"
              ref="cityInputRef"
              id="city-search"
              class="flex-1 text-xs border-0 p-0 bg-transparent focus:ring-0 focus:outline-none"
              v-model="displayedCity"
            />
          </div>

          <div
            v-show="cityResults.length"
            class="absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 border-t-0 rounded-b-lg"
            :class="{
              hidden: !isCityFocused,
              'border-blue-600 border-t-gray-300': isCityFocused,
            }"
          >
            <ul class="max-h-40 overflow-y-auto">
              <li
                v-for="result in cityResults"
                :key="result.label"
                class="px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors"
                @click="handleCityChange(result)"
              >
                {{ result.label }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <p v-if="subText" class="mt-1 text-gray-500">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

import { countrySearch, municipalitySearch } from '@pretto/places'
import { computed, inject, nextTick, ref, watch } from 'vue'
import prettoPlacesConfig from '@/config/pretto_places.json'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { useCountryHelper } from '@/utils/composables/country.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'
import IAddress from '@/types/Address.interface'

const countriesApi = countrySearch.init(prettoPlacesConfig.countriesAppId, prettoPlacesConfig.countriesApiKey)

interface CountriesSearchResult {
  value: string
  label: string
}

interface MunicipalitySearchResult {
  label: string
  value: {
    city: string
    country: string
    zipcode: string
  }
}

const props = defineProps<{
  modelValue: Omit<IAddress, 'street'>
  label?: string
  fieldClass?: string | (string | Record<string, boolean>)[]
  disabled?: boolean
  hasError?: boolean
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Omit<IAddress, 'street'>): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { getCountryName } = useCountryHelper()

const formatCity = (address: Omit<IAddress, 'street'>): string => {
  return `${address.city ?? ''} ${address.zipcode ?? ''}`.trim()
}

const cityRef = ref<HTMLElement | null>(null)
const cityInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<Omit<IAddress, 'street'>>(props.modelValue)
const isCityFocused = ref<boolean>(false)
const displayedCity = ref<string>(formatCity(props.modelValue))
const cityResults = ref<MunicipalitySearchResult[]>([])

const focus = async (isFocus = true): Promise<void> => {
  isCityFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (cityInputRef.value) {
      await nextTick()
      cityInputRef.value.focus()
      await getCityResults()
    }
  } else {
    emit('blur')
  }
}

const handleCityChange = (result: MunicipalitySearchResult): void => {
  innerValue.value.city = result.value.city
  innerValue.value.zipcode = result.value.zipcode
  displayedCity.value = result.label
  isCityFocused.value = false
  emit('blur')
}

const getCityResults = async (): Promise<void> => {
  if (innerValue.value.country === 'fr') {
    try {
      const response = await municipalitySearch.get(displayedCity.value.trim())
      if (typeof response !== 'string') {
        cityResults.value = response
      }
    } catch (error) {
      sendErrorMessage(error as string)
    }
  } else {
    cityResults.value = []
    innerValue.value.city = displayedCity.value
    innerValue.value.zipcode = ''
  }
}

onClickOutside(cityRef, () => focus(false))

const countryRef = ref<HTMLElement | null>(null)
const countryInputRef = ref<HTMLInputElement | null>(null)
const isCountryFocused = ref<boolean>(false)
const countryResults = ref<CountriesSearchResult[]>([])
const countrySearchValue = ref<string>(getCountryName(innerValue.value.country ?? 'fr'))

const focusCountryField = async (isFocus = true): Promise<void> => {
  isCountryFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (countryInputRef.value) {
      await nextTick()
      countryInputRef.value.focus()
      await getCountryResults()
    }
  } else {
    emit('blur')
  }
}

const handleCountryChange = (result: CountriesSearchResult): void => {
  innerValue.value.country = result.value
  countrySearchValue.value = result.label
  isCountryFocused.value = false
}

const getCountryResults = async (): Promise<void> => {
  try {
    if (typeof countriesApi !== 'string') {
      const response = await countriesApi.get(countrySearchValue.value.trim(), { limit: 10 })
      countryResults.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}
onClickOutside(countryRef, () => focusCountryField(false))

watch(() => displayedCity.value, getCityResults)
watch(() => countrySearchValue.value, getCountryResults)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>
