<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <div
      v-if="label"
      class="flex justify-between items-center"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
    >
      <BaseLabel
        :for="incrementfieldId"
        :is-field-inline="isFieldCustomStyle"
        :label-id="incrementfieldId"
        :unit="unit"
        :icon="labelIcon"
        :icon-tooltip-text="labelIconTooltipText"
        :has-icon-action="hasLabelIconAction"
        :label-icon-class="labelIconClass"
        @icon-action-click="$emit('label-icon-action-click')"
      >
        {{ label }}
      </BaseLabel>
    </div>

    <div
      class="flex items-center gap-x-2"
      :class="{
        'justify-self-end': isFieldCustomStyle,
      }"
      @mouseenter="fieldHoverHelper?.handleMouseEnter"
      @mouseleave="fieldHoverHelper?.handleMouseLeave"
    >
      <BaseButton
        class="simple icon flex-none"
        :track-object="$trackedEvents.noTracking"
        @click="decrement"
        :disabled="disabled"
      >
        <MinusIcon class="w-5 h-5" />
      </BaseButton>

      <input
        :id="incrementfieldId"
        class="field text-xs border-0 focus:ring-0 block w-6 p-0 text-center"
        :class="[
          fieldClass,
          {
            'text-gray-400 pointer-events-none': disabled,
          },
        ]"
        type="text"
        inputmode="numeric"
        :min="min"
        :max="max"
        :disabled="disabled"
        v-model="innerValue"
      />

      <BaseButton
        class="simple icon flex-none"
        :track-object="$trackedEvents.noTracking"
        @click="increment"
        :disabled="disabled"
      >
        <PlusIcon class="w-5 h-5" />
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/components/base/button/BaseButton.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { MinusIcon, PlusIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: number | null
    label?: string
    fieldClass?: string | (string | Record<string, boolean>)[]
    min?: number
    max?: number
    disabled?: boolean
    unit?: string
    labelIcon?: Component
    labelIconTooltipText?: string
    hasLabelIconAction?: boolean
    labelIconClass?: string
  }>(),
  {
    min: 0,
    max: 10,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const incrementfieldId = ref<string>(crypto.randomUUID())

const innerValue = ref<number>(props.modelValue ?? 0)

const increment = (): void => {
  if (innerValue.value < props.max) {
    innerValue.value++
  }
}

const decrement = (): void => {
  if (innerValue.value > props.min) {
    innerValue.value--
  }
}

watch(innerValue, (value) => emit('update:modelValue', value))

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? 0),
)
</script>
