import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import EnumField from './EnumField.vue'

import banks from '@/config/banks.json'
import { computed, ref, watch } from 'vue'

import type { Component } from 'vue'
import { EFieldKind } from '@/types/FieldKind.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'BankField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    disabled: { type: Boolean },
    kind: {},
    toggleValue: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const innerValue = ref<typeof props.modelValue>(props.modelValue)

const isMultipleEnabled = computed<boolean>(() => props.kind === EFieldKind.MultipleBankGroup)

const bankOptions = computed<Record<string, string>>(() => {
  let bankList: typeof banks = banks

  if (props.kind === EFieldKind.SimpleBankGroupClient) {
    bankList = banks.filter((bank) => bank.has_client_accounts ?? true)
  }

  return bankList.reduce<Record<string, string>>((list, bank) => {
    list[bank.value] = bank.label

    return list
  }, {})
})

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => {
    if (value) {
      emit('update:modelValue', value)
    }

    emit('blur')
  },
  { deep: true },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(EnumField, {
      label: _ctx.label,
      "label-icon": _ctx.labelIcon,
      "label-icon-tooltip-text": _ctx.labelIconTooltipText,
      "has-label-icon-action": _ctx.hasLabelIconAction,
      "label-icon-class": _ctx.labelIconClass,
      disabled: _ctx.disabled,
      multiple: isMultipleEnabled.value,
      "field-class": _ctx.fieldClass,
      placeholder: _ctx.$t('common.bank_field.bank_placeholder'),
      options: bankOptions.value,
      "is-searchable": "",
      modelValue: innerValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((innerValue).value = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (emit('focus'))),
      onIconActionClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
    }, null, 8, ["label", "label-icon", "label-icon-tooltip-text", "has-label-icon-action", "label-icon-class", "disabled", "multiple", "field-class", "placeholder", "options", "modelValue"])
  ]))
}
}

})