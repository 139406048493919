import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "mt-1 text-gray-500"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-0.5 text-red-600 text-2xs"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { CalendarIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useDateHelpers } from '@/utils/composables/date.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useFormatHelper } from '@/utils/composables/format.helpers'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    disabled: { type: Boolean },
    isHighlighted: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasError: { type: Boolean },
    errorMessage: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    fieldIcon: {},
    subText: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<string | null>(props.modelValue)

const datepickerId = ref<string>(crypto.randomUUID())

const handleFocus = (event: Event): void => {
  if ('showPicker' in (event.target as HTMLInputElement)) {
    ;(event.target as HTMLInputElement).showPicker?.()
  }
  emit('focus')
}

const handleBlur = (): void => {
  emit('blur')
  emit('update:modelValue', innerValue.value || null)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

const { isStringDateValid } = useDateHelpers()
const { formatDatetime } = useFormatHelper()

const handleCopy = (): void => {
  if (innerValue.value) {
    navigator.clipboard.writeText(
      formatDatetime(innerValue.value, {
        format: 'DD/MM/YYYY',
        parsingFormat: ['DD/MM/YYYY', 'YYYY-MM-DD'],
      }),
    )
  }
}

const handlePaste = async (): Promise<void> => {
  const pastedValue = await navigator.clipboard.readText()

  if (pastedValue && isStringDateValid(pastedValue)) {
    innerValue.value = formatDatetime(pastedValue, {
      format: 'YYYY-MM-DD',
      parsingFormat: ['DD/MM/YYYY', 'YYYY-MM-DD'],
    })
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          for: datepickerId.value,
          "is-field-inline": isFieldCustomStyle.value,
          icon: _ctx.labelIcon,
          "has-error": _ctx.hasError,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["class", "for", "is-field-inline", "icon", "has-error", "icon-tooltip-text", "has-icon-action", "icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "relative",
        onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "datepickerRef",
          id: datepickerId.value,
          class: _normalizeClass(["field border border-gray-300 text-xs rounded-lg focus:ring-0 block w-full pl-9 pr-2 py-1 transition-colors peer", [
            _ctx.fieldClass,
            {
              highlight: _ctx.isHighlighted,
              'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
              'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
              'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
            },
          ]]),
          type: "date",
          placeholder: "../../....",
          disabled: _ctx.disabled,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue).value = $event)),
          onFocus: handleFocus,
          onBlur: handleBlur,
          onCopy: handleCopy,
          onPaste: handlePaste
        }, null, 42, _hoisted_1), [
          [_vModelText, innerValue.value]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass(["absolute inset-y-0 left-2 flex items-center transition-colors", {
            'text-gray-400': _ctx.disabled,
            'text-gray-500 peer-focus:text-blue-600': !_ctx.disabled,
          }]),
          for: datepickerId.value
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.fieldIcon ?? _unref(CalendarIcon)), { class: "w-5 h-5" }))
        ], 10, _hoisted_2)
      ], 32),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true),
      (_ctx.hasError && _ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})