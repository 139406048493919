import { AnalyticsBrowser } from '@segment/analytics-next'
import { computed } from 'vue'
import segmentConfig from '@/config/segment.json'
import { storeToRefs } from 'pinia'
import { useFeatureFlags } from '@/utils/composables/feature_flags'
import { useStore } from './store'
import mixpanel from "mixpanel-browser"
import mixpanelConfig from '@/config/mixpanel.json'

import { EEventType, IEvent } from '@/utils/tracking'

const analytics = AnalyticsBrowser.load({ writeKey: segmentConfig.write_key })

if (process.env.NODE_ENV === 'production') {
  mixpanel.init(mixpanelConfig.token, {
    track_pageview: true,
    persistence: "localStorage",
    record_sessions_percent: 100,
    record_mask_text_selector: ".masked",
    record_collect_fonts: true
  })
}

analytics.addSourceMiddleware(({ payload, next }) => {
  if (payload.type() === 'track' || payload.type() === 'page') {
    const segmentDeviceId = payload.obj.anonymousId
    mixpanel.register({ $device_id: segmentDeviceId, distinct_id: segmentDeviceId })
    const sessionReplayProperties = mixpanel.get_session_recording_properties()
    payload.obj.properties = {
      ...payload.obj.properties,
      ...sessionReplayProperties,
    }
  }
  if (payload.type() === 'identify') {
    const userId = payload.obj.userId
    mixpanel.identify(userId as string)
  }
  next(payload)
})

const { featureFlags } = useFeatureFlags()
const enabledFeatureFlags = computed<Record<string, true>>(() =>
  featureFlags.reduce<Record<string, true>>((list, flag) => {
    if (flag.value) {
      list[flag.name] = flag.value
    }

    return list
  }, {}),
)

const trackEvent = (trackedEvent: IEvent, props?: Record<string, string | string[] | undefined>): void => {
  if (trackedEvent.type !== EEventType.NoTracking) {
    const store = useStore()
    const { projectId } = storeToRefs(store)

    const eventProperties = {
      projectId: projectId.value,
      ...enabledFeatureFlags.value,
      ...props,
    }

    if (process.env.NODE_ENV === 'production') {
      analytics[trackedEvent.type](`${trackedEvent.object}:${trackedEvent.action}`, eventProperties)
    }
  }
}

export const useAnalytics = () => ({
  ...analytics,
  trackEvent,
})
