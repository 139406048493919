import { auth0 } from '@/auth'
import { sendErrorMessage, sendSuccessMessage } from '@/utils/messages'
import { useConstants } from '@/utils/composables/constants'
import { useQuery } from '@/plugins/apollo'
import { useMutation } from '@/plugins/apollo'
import { useStore } from '@/utils/composables/store'

import DigitalInsureTokenQuery from '@/schemas/Insurance/DigitalInsureToken.query.gql'
import DigitalInsureDataQuery from '@/schemas/Insurance/DigitalInsureData.query.gql'
import DigitalInsureProjectIdQuery from '@/schemas/Project/DigitalInsureProjectId.query.gql'
import SaveDigitalInsureProjectIdMutation from '@/schemas/Insurance/SaveDigitalInsureProjectId.mutation.gql'

import IDigitalInsureData from '@/types/schemas/Insurance/DigitalInsureData.interface'
import IDigitalInsureTokenQuery from '@/types/schemas/Insurance/DigitalInsureTokenQuery.interface'
import IDigitalInsureProjectIdQuery from '@/types/schemas/Insurance/DigitalInsureProjectIdQuery.interface'
import IGetTarifsQuery from '@/types/digital-insure/GetTarifsQuery.interface'
import ISaveDigitalInsureProjectIdMutation from '@/types/schemas/Insurance/SaveDigitalInsureProjectIdMutation.interface'
import IScenarioInsured from '@/types/digital-insure/ScenarioInsured.interface'
import IScenarioLoan from '@/types/digital-insure/ScenarioLoan.interface'
import IScenarioRequirement from '@/types/digital-insure/ScenarioRequirement.interface'

export interface IInsurancePricePayload {
  contractGrouping: string
  tarificationOptions: {
    commDerogCode: string
    applicationFee: number
    smoothingFeesDuration: number
    calculateMode: string
  }
  tarificationParams: object
  productCodes: string[]
  insuranceType: string
  scenarioRecordDataModel: {
    loans: IScenarioLoan[]
    insureds: IScenarioInsured[]
    requirements: IScenarioRequirement[]
  }
}

interface IInsuranceRecordPayload {
  externalGlobalFolderRecordId: string
  insuranceType: string
  scenarioRecordDataModel: {
    loans: IScenarioLoan[]
    insureds: IScenarioInsured[]
    requirements: IScenarioRequirement[]
  }
}

const { DIGITAL_INSURE_API_BASE_URL } = useConstants()

const getProjectId = (): string | null => {
  const store = useStore()

  return store.projectId
}

const getDigitalInsureData = async (): Promise<IDigitalInsureData> => {
  const { data } = await useQuery<IDigitalInsureData>({
    query: DigitalInsureDataQuery,
    variables: {
      projectId: getProjectId(),
    },
  })

  return data
}

const saveProjectId = async (id: string): Promise<void> => {
  try {
    await useMutation<ISaveDigitalInsureProjectIdMutation>({
      mutation: SaveDigitalInsureProjectIdMutation,
      variables: {
        id: id,
        projectId: getProjectId(),
      },
    })

    sendSuccessMessage(`Id projet Digital Insure sauvegardé: ${id}`)
  } catch (error) {
    if (typeof error === 'string') {
      sendErrorMessage(error)
    }
  }
}

const getDigitalInsureProjectId = async (): Promise<string | null> => {
  const { data } = await useQuery<IDigitalInsureProjectIdQuery>({
    query: DigitalInsureProjectIdQuery,
    variables: {
      projectId: getProjectId(),
    },
  })

  return data.deal.digitalInsureProjectId
}

const DEFAULT_INSURANCE_PRICES_PAYLOAD: Omit<IInsurancePricePayload, 'scenarioRecordDataModel'> = {
  contractGrouping: 'INITIAL',
  tarificationOptions: {
    commDerogCode: 'DEFAULT',
    applicationFee: 100,
    smoothingFeesDuration: 1,
    calculateMode: 'DEFAULT',
  },
  tarificationParams: {},
  productCodes: [],
  insuranceType: 'ADE',
}

// TODO: type response
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createInsuranceRecord = async (payload: IInsuranceRecordPayload): Promise<any> => {
  const token = await getDigitalInsureToken()

  const response = await fetch(
    `${DIGITAL_INSURE_API_BASE_URL}/accelerassur-webservice/ws/rest/v2/ade//businessRecord/createBusinessRecord`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    },
  )

  return response.json()
}

const getDigitalInsureToken = async (): Promise<string> => {
  const { user } = auth0

  const {
    data: { digitalInsureToken },
  } = await useQuery<IDigitalInsureTokenQuery>({
    query: DigitalInsureTokenQuery,
    variables: {
      username: 'FINSPOT_' + user.value!.email!.replace('@oleen.fr', '@bypretto.fr'),
    },
  })

  return digitalInsureToken.token
}

const getInsurancePrices = async (payload: IInsurancePricePayload): Promise<IGetTarifsQuery> => {
  const token = await getDigitalInsureToken()

  const response = await fetch(
    `${DIGITAL_INSURE_API_BASE_URL}/accelerassur-webservice/ws/rest/v2/ade/tarification/getTarifs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    },
  )

  return response.json()
}

export const useInsuranceService = () => ({
  DEFAULT_INSURANCE_PRICES_PAYLOAD,
  getDigitalInsureData,
  getDigitalInsureToken,
  getDigitalInsureProjectId,
  getInsurancePrices,
  createInsuranceRecord,
  saveProjectId,
})
