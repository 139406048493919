import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock, vShow as _vShow, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["title", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "flex-1 pr-6 text-xs truncate min-h-[18px]"
}
const _hoisted_4 = { class: "max-h-40 overflow-y-auto" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "px-3 py-1.5"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-1 text-gray-500 whitespace-pre-line"
}
const _hoisted_9 = {
  key: 1,
  class: "mt-1 text-red-600 text-xs"
}

import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseButton from '@/components/base/button/BaseButton.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/20/solid'

import { computed, inject, nextTick, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'

import type { Component } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import { TOption, TOptions, TSubOption } from '@/types/Option.type'
import { LocaleMessageValue } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'EnumField',
  props: {
    modelValue: {},
    label: {},
    options: {},
    fieldClass: {},
    disabled: { type: Boolean },
    placeholder: {},
    nullable: { type: Boolean },
    multiple: { type: Boolean },
    isSearchable: { type: Boolean },
    isButton: { type: Boolean },
    onlyShowPlaceholder: { type: Boolean },
    isSubDirectionLeft: { type: Boolean },
    hasError: { type: Boolean },
    errorMessage: {},
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    subText: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const selectRef = ref<HTMLElement | null>(null)
const innerValue = ref<string | null | string[]>(props.modelValue)
const isFocused = ref<boolean>(false)
const searchValue = ref<string>('')
const searchInputRef = ref<HTMLInputElement | null>(null)

const filteredOptions = computed<TOptions | LocaleMessageValue>(() => {
  if (props.isSearchable && searchValue.value) {
    const filteredOptions = Object.entries(props.options).filter(([, option]) => {
      if (typeof option !== 'string') return false

      // Remove diacritics from search value and option
      // Source: https://stackoverflow.com/a/51874002
      const normalizedOption = option.normalize('NFD').replace(/\p{Diacritic}/gu, '')
      const normalizedSearchValue = searchValue.value.normalize('NFD').replace(/\p{Diacritic}/gu, '')

      return RegExp(normalizedSearchValue, 'i').test(normalizedOption)
    })

    return Object.fromEntries(filteredOptions)
  } else {
    return props.options
  }
})

const displayedOptions = computed<(TOption & { key: string })[]>(() =>
  Object.entries(filteredOptions.value).reduce<(TOption & { key: string })[]>((list, [key, value], index) => {
    const lastGroup = list[index - 1]?.group ?? 'group'

    if (typeof value === 'string') {
      list.push({
        key,
        label: value,
        group: lastGroup,
      })
    } else {
      list.push({
        key,
        group: value.group ?? lastGroup,
        ...value,
      })
    }

    return list
  }, []),
)

const displayedValue = computed(() => {
  let value: string | undefined

  if (props.onlyShowPlaceholder && props.placeholder) {
    value = props.placeholder
  } else if (
    (!innerValue.value || (Array.isArray(innerValue.value) && innerValue.value.length === 0)) &&
    props.placeholder
  ) {
    value = props.placeholder
  } else if (typeof innerValue.value === 'string') {
    const option =
      displayedOptions.value.find((option) => option.key === innerValue.value)?.label ||
      (
        Object.values(props.options)
          .filter((option) => typeof option !== 'string')
          .find((option) => (option as TSubOption)?.children[innerValue.value as string]) as TSubOption | undefined
      )?.children[innerValue.value]

    if (typeof option === 'string') {
      value = option
    }
  } else {
    value = (innerValue.value as string[])
      ?.map((value) => displayedOptions.value.find((option) => option.key === value)?.label)
      .join(', ')
  }

  return value
})

const focus = async (isFocus = true): Promise<void> => {
  if (!props.disabled) {
    if (!isFocused.value) {
      isFocused.value = isFocus

      if (isFocus) {
        emit('focus')
        await nextTick()
        searchInputRef.value?.focus()
      }
    } else {
      isFocused.value = isFocus
      emit('blur')
    }
  }
}

const handleChange = (value: string | null): void => {
  if (props.multiple) {
    if (value && Array.isArray(innerValue.value)) {
      const valueIndex = innerValue.value.indexOf(value)
      valueIndex >= 0 ? innerValue.value.splice(valueIndex, 1) : innerValue.value.push(value)
    }
  } else {
    const option = displayedOptions.value.find((option) => option.key === value)
    const hasChildren = !!option && 'children' in option

    if (!hasChildren) {
      innerValue.value = value
    }

    isFocused.value = false
    emit('blur')
  }
}

const isSelected = (value: string): boolean => {
  return (
    !!innerValue.value &&
    ((Array.isArray(innerValue.value) && (innerValue.value as string[]).indexOf(value) >= 0) ||
      innerValue.value === value)
  )
}

onClickOutside(selectRef, () => focus(false), {
  ignore: [searchInputRef],
})

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => {
    emit('update:modelValue', value)
  },
  { deep: true },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative", {
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between items-center self-start", {
        'mb-1': _ctx.label && !isFieldCustomStyle.value,
      }])
    }, [
      (_ctx.label)
        ? (_openBlock(), _createBlock(BaseLabel, {
            key: 0,
            "is-field-inline": isFieldCustomStyle.value,
            disabled: _ctx.disabled,
            icon: _ctx.labelIcon,
            "icon-tooltip-text": _ctx.labelIconTooltipText,
            "has-icon-action": _ctx.hasLabelIconAction,
            "icon-class": _ctx.labelIconClass,
            onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click'))),
            onClick: focus
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["is-field-inline", "disabled", "icon", "icon-tooltip-text", "has-icon-action", "icon-class"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "label-right")
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        ref_key: "selectRef",
        ref: selectRef,
        class: "relative",
        onMouseenter: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, [
        (_ctx.isButton && !isFocused.value)
          ? (_openBlock(), _createBlock(BaseButton, {
              key: 0,
              class: "simple small",
              disabled: _ctx.disabled,
              "track-object": _ctx.$trackedEvents.noTracking,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (focus(!isFocused.value)))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(PlusIcon), { class: "w-5 h-5" }),
                _createTextVNode(" " + _toDisplayString(displayedValue.value), 1)
              ]),
              _: 1
            }, 8, ["disabled", "track-object"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["field flex border border-gray-300 text-xs rounded-lg cursor-pointer select-none transition-colors px-2 py-1.75", [
            _ctx.fieldClass,
            {
              'border-blue-600 border-b-0 rounded-b-none hover:!border-blue-600': isFocused.value,
              'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
              'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
              'border-red-500 focus:border-red-500': _ctx.hasError && !isFocused.value,
            },
          ]]),
              title: displayedValue.value,
              disabled: _ctx.disabled,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (focus(!isFocused.value)))
            }, [
              (!_ctx.isSearchable || !isFocused.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(displayedValue.value), 1))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("input", {
                ref_key: "searchInputRef",
                ref: searchInputRef,
                class: "flex-1 border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchValue).value = $event)),
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, null, 512), [
                [_vShow, _ctx.isSearchable && isFocused.value],
                [_vModelText, searchValue.value]
              ]),
              (!_ctx.disabled)
                ? (_openBlock(), _createBlock(_unref(ChevronDownIcon), {
                    key: 1,
                    class: _normalizeClass(["w-5 h-5 text-gray-500 absolute right-3 inset-y-0 my-auto transition-transform", { 'rotate-180': isFocused.value }])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_2)),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg border-t-gray-300", {
            hidden: !isFocused.value,
            'ring-blue-600 border-blue-600': isFocused.value && !_ctx.isButton,
          }])
        }, [
          _createElementVNode("ul", _hoisted_4, [
            (_ctx.nullable)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass(["px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors min-h-[30px]", { 'font-semibold': innerValue.value === null }]),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (handleChange(null)))
                }, _toDisplayString(_ctx.placeholder ?? '-'), 3))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedOptions.value, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: option.key,
                class: _normalizeClass(["px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors group flex items-center gap-x-3", {
                'justify-between': !_ctx.isSubDirectionLeft,
                'border-b border-gray-300 mb-1':
                  index !== displayedOptions.value.length - 1 && option.group !== displayedOptions.value[index + 1].group,
              }]),
                onClick: ($event: any) => ('action' in option ? option.action() : handleChange(option.key))
              }, [
                ('children' in option)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.isSubDirectionLeft)
                        ? (_openBlock(), _createBlock(_unref(ChevronLeftIcon), {
                            key: 0,
                            class: "text-gray-500 w-4 h-4"
                          }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(option.label) + " ", 1),
                      (!_ctx.isSubDirectionLeft)
                        ? (_openBlock(), _createBlock(_unref(ChevronRightIcon), {
                            key: 1,
                            class: "text-gray-500 w-4 h-4"
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("ul", {
                        class: _normalizeClass(["absolute top-0 bg-white border border-gray-300 rounded-lg invisible group-hover:visible max-h-40 overflow-y-auto min-w-max", {
                    'right-full -mr-2': _ctx.isSubDirectionLeft,
                    'left-full -ml-2': !_ctx.isSubDirectionLeft,
                  }])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.children, (child, childKey) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: childKey,
                            class: _normalizeClass(["px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors", { 'font-bold': isSelected(childKey) }]),
                            onClick: _withModifiers(($event: any) => (handleChange(childKey)), ["stop"])
                          }, _toDisplayString(child), 11, _hoisted_6))
                        }), 128))
                      ], 2)
                    ], 64))
                  : ('action' in option)
                    ? (_openBlock(), _createBlock(BaseAction, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.label), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(_toDisplayString(option.label), 1)
                      ], 64)),
                (isSelected(option.key))
                  ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                      key: 3,
                      class: "flex-none text-green-500 w-5 h-5"
                    }))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_5))
            }), 128)),
            (!displayedOptions.value.length)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.$t('common.enum_field.no_result')), 1))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ], 544),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true),
      (_ctx.hasError && _ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})