import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "absolute top-full left-0 mt-0.5 text-orange-500 text-2xs"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { VueTelInput } from 'vue-tel-input'
import { ExclamationTriangleIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch, nextTick, Component } from 'vue'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useI18n } from 'vue-i18n'

import 'vue-tel-input/vue-tel-input.css'


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneField',
  props: {
    modelValue: {},
    disabled: { type: Boolean },
    hasLabelIconAction: { type: Boolean },
    label: {},
    labelIcon: {},
    labelIconClass: {},
    labelIconTooltipText: {},
    placeholder: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n()
const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)
const isFieldInline = inject<boolean>('isFieldInline', false)

const props = __props

const emit = __emit

const innerValue = ref<string | null>(props.modelValue ?? null)
const warningMessage = ref<string | undefined>(undefined)
const areFlagsVisible = ref<boolean>(false)

const options = computed(() => ({
  dropdownOptions: {
    showSearchBox: true,
    showFlags: true,
  },
  inputOptions: {
    placeholder: props.placeholder,
  },
  autoFormat: false,
}))

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const handleBlur = () => {
  validate()
  emit('blur')
}

const handleValidate = () => {
  validate()
}

const handleCountryChanged = () => {
  validate()
}

const handleFocus = () => {
  warningMessage.value = undefined
  emit('focus')
}

const handleOpen = () => {
  areFlagsVisible.value = true
}

const handleClose = () => {
  areFlagsVisible.value = false
}

const phoneNumberUtil = PhoneNumberUtil.getInstance()

const validate = async () => {
  await nextTick()
  try {
    if (!innerValue.value) return

    const parsedPhone = phoneNumberUtil.parse(innerValue.value, 'FR')

    if (!phoneNumberUtil.isValidNumber(parsedPhone)) {
      throw new Error()
    }

    warningMessage.value = undefined
  } catch (error) {
    warningMessage.value = t('errors.invalid_phone_number')
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative", {
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    }]),
    onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          "is-field-inline": isFieldCustomStyle.value,
          disabled: _ctx.disabled,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["class", "is-field-inline", "disabled", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(VueTelInput), _mergeProps({
      modelValue: innerValue.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((innerValue).value = $event)),
      "default-country": "FR",
      mode: "international",
      onValidate: handleValidate,
      onCountryChanged: handleCountryChanged,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onOpen: handleOpen,
      onClose: handleClose
    }, options.value, { disabled: _ctx.disabled }), {
      "arrow-icon": _withCtx(() => [
        _createVNode(_unref(ChevronDownIcon), {
          class: _normalizeClass(["w-5 h-5 transition-transform", { 'rotate-180': areFlagsVisible.value }])
        }, null, 8, ["class"])
      ]),
      _: 1
    }, 16, ["modelValue", "disabled"]),
    (warningMessage.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createVNode(_unref(ExclamationTriangleIcon), { class: "w-5 h-5 text-orange-500 inline" }),
          _createTextVNode(" " + _toDisplayString(warningMessage.value), 1)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}
}

})