import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-h-40 overflow-y-auto" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-base text-gray-500" }

import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import BaseModal from '@/components/base/modal/BaseModal.vue'
import CityField from './CityField.vue'
import TextField from './TextField.vue'
import { MapPinIcon } from '@heroicons/vue/20/solid'

import { addressSearch } from '@pretto/places'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { trackingKey } from '@/utils/tracking'
import { useAnalytics } from '@/utils/composables/segment'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useFormatHelper } from '@/utils/composables/format.helpers'

import type { Component } from 'vue'
import IAddress from '@/types/Address.interface'

interface AddressSearchResult {
  label: string
  value: {
    city: string | null
    country: string | null
    street: string | null
    zipcode: string | null
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LocalisationField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    disabled: { type: Boolean },
    hasError: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const block = inject<string>('block')

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)
const { formatAddress } = useFormatHelper()

const localisationRef = ref<HTMLElement | null>(null)
const localisationInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<IAddress>(props.modelValue)
const isFocused = ref<boolean>(false)
const displayedValue = ref<string>(formatAddress(props.modelValue))
const results = ref<AddressSearchResult[]>([])
const isAddressModalVisible = ref<boolean>(false)

const focus = async (isFocus = true): Promise<void> => {
  isFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (localisationInputRef.value) {
      await nextTick()
      localisationInputRef.value.focus()
      await getResults()
    }
  } else {
    emit('blur')
  }
}

const handleChange = (result: AddressSearchResult): void => {
  innerValue.value = result.value
  displayedValue.value = result.label
  isFocused.value = false
  emit('blur')
}

const addressModalValue = ref<IAddress>({ ...innerValue.value })
const handleValidate = (): void => {
  innerValue.value = { ...addressModalValue.value }
  isAddressModalVisible.value = false
  displayedValue.value = formatAddress(innerValue.value)
}

const { trackEvent } = useAnalytics()
const trackedEvents = inject(trackingKey)

const handleModalOpen = (): void => {
  if (trackedEvents) {
    trackEvent(trackedEvents.localisationFieldAction, {
      object: block,
    })
  }

  isAddressModalVisible.value = true
}

const handleModalClose = (): void => {
  isAddressModalVisible.value = false
  addressModalValue.value = { ...innerValue.value }
}

const getResults = async (): Promise<void> => {
  try {
    const response = await addressSearch.get(displayedValue.value.trim())
    if (typeof response !== 'string') {
      results.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}

const handleBlur = (): void => {
  if (!displayedValue.value) {
    innerValue.value = {
      street: null,
      city: null,
      zipcode: null,
      country: null,
    }
  } else {
    displayedValue.value = formatAddress(innerValue.value)
  }
}

onClickOutside(localisationRef, () => focus(false))

watch(() => displayedValue.value, getResults)

watch(
  () => props.modelValue,
  (value) => {
    innerValue.value = value
    displayedValue.value = formatAddress(innerValue.value)
  },
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2 items-start': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          "is-field-inline": isFieldCustomStyle.value,
          disabled: _ctx.disabled,
          "has-error": _ctx.hasError,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (focus())),
          onIconActionClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["class", "is-field-inline", "disabled", "has-error", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        ref_key: "localisationRef",
        ref: localisationRef,
        class: "relative",
        onMouseenter: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["field border border-gray-300 rounded-lg flex items-center px-2 py-1.5 cursor-text transition-colors", [
            _ctx.fieldClass,
            {
              'border-blue-600  hover:border-blue-600': isFocused.value,
              'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
              'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
              'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
              'border-b-0 rounded-b-none': isFocused.value && results.value.length > 0,
            },
          ]])
        }, [
          _createElementVNode("label", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (focus()))
          }, [
            _createVNode(_unref(MapPinIcon), {
              class: _normalizeClass(["w-5 h-5 mr-2", {
                'text-gray-400': _ctx.disabled,
                'text-gray-500': !_ctx.disabled,
              }])
            }, null, 8, ["class"])
          ]),
          (!isFocused.value)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "flex-1 pr-6 text-xs truncate min-h-[18px]",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (focus(!isFocused.value)))
              }, _toDisplayString(displayedValue.value), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("input", {
            ref_key: "localisationInputRef",
            ref: localisationInputRef,
            class: "flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((displayedValue).value = $event)),
            onBlur: handleBlur
          }, null, 544), [
            [_vShow, isFocused.value],
            [_vModelText, displayedValue.value]
          ])
        ], 2),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg", {
            hidden: !isFocused.value,
            'ring-blue-500 border-blue-500 border-t-gray-300': isFocused.value,
          }])
        }, [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results.value, (result) => {
              return (_openBlock(), _createElementBlock("li", {
                key: result.label,
                class: "px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors",
                onClick: ($event: any) => (handleChange(result))
              }, _toDisplayString(result.label), 9, _hoisted_2))
            }), 128))
          ])
        ], 2), [
          [_vShow, results.value.length]
        ])
      ], 544),
      _createVNode(BaseAction, {
        class: "mt-1 text-xs",
        onClick: handleModalOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.localisation_field.unknown_address')), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(BaseModal, {
      title: _ctx.$t('common.localisation_field.modal.title'),
      "is-visible": isAddressModalVisible.value,
      onClose: handleModalClose,
      onValidate: handleValidate
    }, {
      default: _withCtx(() => [
        _createVNode(CityField, {
          modelValue: addressModalValue.value,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((addressModalValue).value = $event)),
          label: _ctx.$t('common.localisation_field.modal.city')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(TextField, {
          modelValue: addressModalValue.value.street,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((addressModalValue.value.street) = $event)),
          label: _ctx.$t('common.localisation_field.modal.street')
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('common.localisation_field.modal.address')) + " : " + _toDisplayString(_unref(formatAddress)(addressModalValue.value)), 1)
      ]),
      _: 1
    }, 8, ["title", "is-visible"])
  ], 2))
}
}

})