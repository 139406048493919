import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["id", "value", "name"]
const _hoisted_3 = ["for"]

import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useAnalytics } from '@/utils/composables/segment'

import { IEvent } from '@/utils/tracking'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSegmentedBar',
  props: {
    modelValue: {},
    options: {},
    label: {},
    optionClasses: {},
    trackObject: {}
  },
  emits: ["update:modelValue", "blur"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<typeof props.modelValue>(props.modelValue)
const segmentedbarfieldId = ref<string>(crypto.randomUUID())

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

const { trackEvent } = useAnalytics()

watch(
  () => innerValue.value,
  (value) => {
    trackEvent(props.trackObject, {
      value: innerValue.value ? innerValue.value : undefined,
    })

    emit('update:modelValue', value)
  },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative", {
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between items-center", {
        'mb-1': !isFieldCustomStyle.value,
      }])
    }, [
      (_ctx.label)
        ? (_openBlock(), _createBlock(BaseLabel, {
            key: 0,
            "is-field-inline": isFieldCustomStyle.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["is-field-inline"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "label-right")
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (value, key, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "flex-1 flex text-center"
        }, [
          _withDirectives(_createElementVNode("input", {
            id: `${segmentedbarfieldId.value}-${key}`,
            type: "radio",
            value: key,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((innerValue).value = $event)),
            name: segmentedbarfieldId.value,
            class: "hidden peer"
          }, null, 8, _hoisted_2), [
            [_vModelRadio, innerValue.value]
          ]),
          _createElementVNode("label", {
            for: `${segmentedbarfieldId.value}-${key}`,
            class: _normalizeClass(["flex-1 py-2 px-4 font-semibold bg-white cursor-pointer border-y border-r border-gray-200 transition-colors hover:bg-gray-50 peer-checked:text-blue-600 peer-checked:bg-blue-50", [
            _ctx.optionClasses,
            {
              'rounded-l-lg border-l': index === 0,
              'rounded-r-lg': index === Object.keys(_ctx.options).length - 1,
            },
          ]])
          }, _toDisplayString(value), 11, _hoisted_3)
        ]))
      }), 128))
    ])
  ], 2))
}
}

})