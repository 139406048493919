<template>
  <div
    class="relative"
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
    @mouseenter="fieldHoverHelper?.handleMouseEnter"
    @mouseleave="fieldHoverHelper?.handleMouseLeave"
  >
    <BaseLabel
      v-if="label"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :is-field-inline="isFieldCustomStyle"
      :disabled="disabled"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <VueTelInput
      v-model="innerValue"
      default-country="FR"
      mode="international"
      @validate="handleValidate"
      @country-changed="handleCountryChanged"
      @focus="handleFocus"
      @blur="handleBlur"
      @open="handleOpen"
      @close="handleClose"
      v-bind="options"
      :disabled="disabled"
    >
      <template #arrow-icon>
        <ChevronDownIcon class="w-5 h-5 transition-transform" :class="{ 'rotate-180': areFlagsVisible }" />
      </template>
    </VueTelInput>

    <p v-if="warningMessage" class="absolute top-full left-0 mt-0.5 text-orange-500 text-2xs">
      <ExclamationTriangleIcon class="w-5 h-5 text-orange-500 inline" /> {{ warningMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { VueTelInput } from 'vue-tel-input'
import { ExclamationTriangleIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch, nextTick, Component } from 'vue'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useI18n } from 'vue-i18n'

import 'vue-tel-input/vue-tel-input.css'

const { t } = useI18n()
const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)
const isFieldInline = inject<boolean>('isFieldInline', false)

const props = defineProps<{
  modelValue: string | null
  disabled?: boolean
  hasLabelIconAction?: boolean
  label?: string
  labelIcon?: Component
  labelIconClass?: string
  labelIconTooltipText?: string
  placeholder?: string | null
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const innerValue = ref<string | null>(props.modelValue ?? null)
const warningMessage = ref<string | undefined>(undefined)
const areFlagsVisible = ref<boolean>(false)

const options = computed(() => ({
  dropdownOptions: {
    showSearchBox: true,
    showFlags: true,
  },
  inputOptions: {
    placeholder: props.placeholder,
  },
  autoFormat: false,
}))

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const handleBlur = () => {
  validate()
  emit('blur')
}

const handleValidate = () => {
  validate()
}

const handleCountryChanged = () => {
  validate()
}

const handleFocus = () => {
  warningMessage.value = undefined
  emit('focus')
}

const handleOpen = () => {
  areFlagsVisible.value = true
}

const handleClose = () => {
  areFlagsVisible.value = false
}

const phoneNumberUtil = PhoneNumberUtil.getInstance()

const validate = async () => {
  await nextTick()
  try {
    if (!innerValue.value) return

    const parsedPhone = phoneNumberUtil.parse(innerValue.value, 'FR')

    if (!phoneNumberUtil.isValidNumber(parsedPhone)) {
      throw new Error()
    }

    warningMessage.value = undefined
  } catch (error) {
    warningMessage.value = t('errors.invalid_phone_number')
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>

<style>
.vue-tel-input {
  @apply flex justify-center items-center shrink;
  @apply border-none focus-within:shadow-none;
  @apply antialiased font-medium cursor-pointer;
}

.vue-tel-input > .vti__input {
  @apply border-solid border-y border-r border-l-0 rounded-r-md;
  @apply border-gray-300 transition-colors hover:border-gray-500 focus:shadow-none focus:ring-0;
  @apply box-content py-1 px-4 h-6;
  @apply leading-4 text-xs font-medium bg-transparent;
}

.vue-tel-input.disabled > .vti__input {
  @apply bg-gray-100 hover:border-gray-300 focus:shadow-none focus:ring-0;
}

.vti__dropdown {
  @apply p-0 m-0 h-full hover:bg-white;
}

.vti__selection {
  @apply border-gray-300 transition-colors hover:border-gray-500 focus:shadow-none focus:ring-0;
  @apply flex items-center gap-2;
  @apply rounded-l-md border;
  @apply box-content h-6 py-1 px-2;
}

.vue-tel-input.disabled .vti__selection {
  @apply bg-gray-100 hover:border-gray-300 focus:shadow-none focus:ring-0 cursor-not-allowed;
}

.vti__flag {
  @apply m-0 shadow-none;
}

.vti__dropdown-list {
  @apply absolute;
  @apply p-0 m-0 w-[390px] overflow-y-scroll list-none;
}

.vti__dropdown.open {
  @apply bg-white;
}

.vti__dropdown-list.below {
  @apply top-[40px];
  @apply z-10 bg-white rounded shadow max-w-max;
}

.vti__search_box {
  @apply border-0;
  @apply focus:ring-0;
  @apply box-content py-1 pl-2 h-6;
  @apply leading-4 text-xs font-medium bg-transparent;
}

li.vti__dropdown-item {
  @apply px-3 py-1.5 hover:bg-blue-50 transition-colors cursor-pointer;
  @apply flex justify-start items-center;
}

li.vti__dropdown-item > strong {
  @apply font-medium;
}
</style>
