import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-h-40 overflow-y-auto" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "max-h-40 overflow-y-auto" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "mt-1 text-gray-500"
}

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

import { countrySearch, municipalitySearch } from '@pretto/places'
import { computed, inject, nextTick, ref, watch } from 'vue'
import prettoPlacesConfig from '@/config/pretto_places.json'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { useCountryHelper } from '@/utils/composables/country.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'
import IAddress from '@/types/Address.interface'

interface CountriesSearchResult {
  value: string
  label: string
}

interface MunicipalitySearchResult {
  label: string
  value: {
    city: string
    country: string
    zipcode: string
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CityField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    disabled: { type: Boolean },
    hasError: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    subText: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const countriesApi = countrySearch.init(prettoPlacesConfig.countriesAppId, prettoPlacesConfig.countriesApiKey)

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { getCountryName } = useCountryHelper()

const formatCity = (address: Omit<IAddress, 'street'>): string => {
  return `${address.city ?? ''} ${address.zipcode ?? ''}`.trim()
}

const cityRef = ref<HTMLElement | null>(null)
const cityInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<Omit<IAddress, 'street'>>(props.modelValue)
const isCityFocused = ref<boolean>(false)
const displayedCity = ref<string>(formatCity(props.modelValue))
const cityResults = ref<MunicipalitySearchResult[]>([])

const focus = async (isFocus = true): Promise<void> => {
  isCityFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (cityInputRef.value) {
      await nextTick()
      cityInputRef.value.focus()
      await getCityResults()
    }
  } else {
    emit('blur')
  }
}

const handleCityChange = (result: MunicipalitySearchResult): void => {
  innerValue.value.city = result.value.city
  innerValue.value.zipcode = result.value.zipcode
  displayedCity.value = result.label
  isCityFocused.value = false
  emit('blur')
}

const getCityResults = async (): Promise<void> => {
  if (innerValue.value.country === 'fr') {
    try {
      const response = await municipalitySearch.get(displayedCity.value.trim())
      if (typeof response !== 'string') {
        cityResults.value = response
      }
    } catch (error) {
      sendErrorMessage(error as string)
    }
  } else {
    cityResults.value = []
    innerValue.value.city = displayedCity.value
    innerValue.value.zipcode = ''
  }
}

onClickOutside(cityRef, () => focus(false))

const countryRef = ref<HTMLElement | null>(null)
const countryInputRef = ref<HTMLInputElement | null>(null)
const isCountryFocused = ref<boolean>(false)
const countryResults = ref<CountriesSearchResult[]>([])
const countrySearchValue = ref<string>(getCountryName(innerValue.value.country ?? 'fr'))

const focusCountryField = async (isFocus = true): Promise<void> => {
  isCountryFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (countryInputRef.value) {
      await nextTick()
      countryInputRef.value.focus()
      await getCountryResults()
    }
  } else {
    emit('blur')
  }
}

const handleCountryChange = (result: CountriesSearchResult): void => {
  innerValue.value.country = result.value
  countrySearchValue.value = result.label
  isCountryFocused.value = false
}

const getCountryResults = async (): Promise<void> => {
  try {
    if (typeof countriesApi !== 'string') {
      const response = await countriesApi.get(countrySearchValue.value.trim(), { limit: 10 })
      countryResults.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}
onClickOutside(countryRef, () => focusCountryField(false))

watch(() => displayedCity.value, getCityResults)
watch(() => countrySearchValue.value, getCountryResults)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          "is-field-inline": isFieldCustomStyle.value,
          disabled: _ctx.disabled,
          "has-error": _ctx.hasError,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (focus())),
          onIconActionClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["class", "is-field-inline", "disabled", "has-error", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "flex",
        onMouseenter: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, [
        _createElementVNode("div", {
          class: "flex-shrink relative",
          ref_key: "countryRef",
          ref: countryRef
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["field border border-gray-300 text-xs rounded-lg rounded-r-none flex cursor-pointer select-none transition-colors px-2", [
              _ctx.fieldClass,
              {
                'border-blue-600 hover:border-blue-600 py-1.75': isCountryFocused.value,
                'py-1': !isCountryFocused.value,
                'border-b-gray-300 rounded-b-none': isCountryFocused.value && countryResults.value.length > 0,
                'border-r-0': isCityFocused.value,
                'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
                'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
                'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
              },
            ]])
          }, [
            (!isCountryFocused.value)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "pr-7 text-base truncate min-h-[16px]",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (focusCountryField(!isCountryFocused.value)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["fi", [`fi-${innerValue.value.country}`]])
                  }, null, 2)
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("input", {
              ref_key: "countryInputRef",
              ref: countryInputRef,
              id: "country-search",
              class: "flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none w-full",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((countrySearchValue).value = $event))
            }, null, 512), [
              [_vShow, isCountryFocused.value],
              [_vModelText, countrySearchValue.value]
            ]),
            _createVNode(_unref(ChevronDownIcon), {
              class: _normalizeClass(["w-5 h-5 text-gray-500 absolute right-2 inset-y-0 my-auto transition-transform", { 'rotate-180': isCountryFocused.value && countryResults.value.length > 0 }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (focusCountryField(!isCountryFocused.value)))
            }, null, 8, ["class"])
          ], 2),
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 border-t-0 rounded-b-lg overflow-hidden", {
              hidden: !isCountryFocused.value,
              'border-blue-600 border-t-gray-300': isCountryFocused.value,
            }])
          }, [
            _createElementVNode("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countryResults.value, (result) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: result.label,
                  class: "px-2 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors",
                  onClick: ($event: any) => (handleCountryChange(result))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["mr-1 fi", [`fi-${result.value}`]])
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(result.label), 1)
                ], 8, _hoisted_2))
              }), 128))
            ])
          ], 2), [
            [_vShow, countryResults.value.length]
          ])
        ], 512),
        _createElementVNode("div", {
          class: "flex-1 relative",
          ref_key: "cityRef",
          ref: cityRef
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["field flex border border-gray-300 text-xs rounded-lg rounded-l-none cursor-text transition-colors px-2 py-1.75", [
              _ctx.fieldClass,
              {
                'border-blue-600 hover:border-blue-600': isCityFocused.value,
                'border-b-gray-300 rounded-b-none': isCityFocused.value && cityResults.value.length > 0,
                'border-l-0': !isCityFocused.value,
                'bg-gray-50 pointer-events-none': _ctx.disabled,
                'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
                'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
              },
            ]])
          }, [
            (!isCityFocused.value)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "flex-1 text-xs truncate min-h-[18px]",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (focus(!isCityFocused.value)))
                }, _toDisplayString(displayedCity.value), 1))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("input", {
              ref_key: "cityInputRef",
              ref: cityInputRef,
              id: "city-search",
              class: "flex-1 text-xs border-0 p-0 bg-transparent focus:ring-0 focus:outline-none",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((displayedCity).value = $event))
            }, null, 512), [
              [_vShow, isCityFocused.value],
              [_vModelText, displayedCity.value]
            ])
          ], 2),
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 border-t-0 rounded-b-lg", {
              hidden: !isCityFocused.value,
              'border-blue-600 border-t-gray-300': isCityFocused.value,
            }])
          }, [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cityResults.value, (result) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: result.label,
                  class: "px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors",
                  onClick: ($event: any) => (handleCityChange(result))
                }, _toDisplayString(result.label), 9, _hoisted_4))
              }), 128))
            ])
          ], 2), [
            [_vShow, cityResults.value.length]
          ])
        ], 512)
      ], 32),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})