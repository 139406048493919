import { createToast, ContentObject, withProps } from 'mosha-vue-toastify'
import { get } from 'lodash'
import project_schema_helper from './project_schema_helper'
import i18n from './i18n'

import BaseToast from '@/components/base/toast/BaseToast.vue'
import BankRequestToast from '@/components/base/toast/BankRequestToast.vue'
import { ref } from 'vue'

export function sendErrorMessage(
  error?: string | { code: string; message?: string },
  title = 'Une erreur est survenue',
) {
  const payload: ContentObject = {
    title,
  }

  if (error) {
    payload.description =
      typeof error === 'string'
        ? error
        : i18n.global.t(`errors.${error.code}`, i18n.global.t('errors.default', { code: error.message ?? error.code }))
  }

  createToast(payload, {
    type: 'danger',
    timeout: 50000,
    toastBackgroundColor: 'rgb(220, 38, 38)',
  })
}

export function sendWarningMessage(warning: string, title?: string) {
  const payload = {
    description: warning,
    title: title ?? undefined,
  }

  createToast(payload, {
    type: 'warning',
    timeout: -1,
    toastBackgroundColor: 'rgb(249, 115, 22)',
  })
}

export function sendSuccessMessage(success: string, title?: string) {
  const payload = {
    description: success,
    title: title ?? undefined,
  }

  createToast(payload, {
    type: 'success',
    toastBackgroundColor: 'rgb(14, 159, 110)',
  })
}

export function sendInfoMessage(message: string, title?: string) {
  const payload = {
    description: message,
    title: title ?? undefined,
  }

  createToast(payload, {
    type: 'info',
    toastBackgroundColor: 'rgb(17, 24, 39)',
    showIcon: true,
  })
}

const closeFn = ref<() => void>()

export function sendCustomMessage(payload: Record<string, unknown>, callback?: () => void) {
  const componentProps = {
    ...payload,
    callback: undefined as (() => void) | undefined,
  }

  if (callback) {
    componentProps.callback = () => {
      callback()
      closeFn.value?.()
    }
  }

  const { close } = createToast(withProps(BaseToast, componentProps), {
    type: 'danger',
    timeout: 50000,
    toastBackgroundColor: 'rgb(220, 38, 38)',
  })

  closeFn.value = close
}

export function sendBankRequestErrorMessage(payload: Record<string, unknown>) {
  createToast(withProps(BankRequestToast, payload), {
    type: 'warning',
    timeout: -1,
    toastBackgroundColor: 'rgb(249, 115, 22)',
  })
}

export const translateErrors = (errors: string[]): string[] => {
  const getDesc = (cleanPath: string, step: number) =>
    get(project_schema_helper, cleanPath.split('.').slice(0, -step).join('.')).desc

  const translations = errors.map((error) => {
    if (error.includes('account_id')) {
      return i18n.global.t('errors.account_id')
    }

    if (error.includes('brokerage_fees')) {
      return i18n.global.t('errors.brokerage_fees')
    }

    if (error.includes('application_fees')) {
      return i18n.global.t('errors.application_fees')
    }

    if (error.includes('contribution')) {
      return i18n.global.t('errors.contribution')
    }

    if (error.includes('loan_duration')) {
      return i18n.global.t('errors.loan_duration')
    }

    if (error.includes('franchise_duration')) {
      return i18n.global.t('errors.franchise_duration')
    }

    if (error.includes('guaranty_fees')) {
      return i18n.global.t('errors.guaranty_fees')
    }

    const [rawPath] = error.split(' ')

    if (rawPath.includes('project')) {
      const path = rawPath.substring(8)
      const arrayItemsPattern = /\[\d+\]/g // Ex : profile.mortgagors[0].age

      if (path.match(arrayItemsPattern)) {
        const index = path.match(arrayItemsPattern)?.[0][1]
        const cleanPath = path.replace(arrayItemsPattern, '')

        return get(project_schema_helper, cleanPath)
          ? `${getDesc(cleanPath, 1)} n°${Number(index) + 1} : ${getDesc(cleanPath, 0)}`
          : `${getDesc(cleanPath, 2)} n°${Number(index) + 1} : ${getDesc(cleanPath, 1)}`
      } else {
        return get(project_schema_helper, path)
          ? `${getDesc(path, 1)} : ${getDesc(path, 0)}`
          : `${getDesc(path, 2)} : ${getDesc(path, 1)}`
      }
    } else {
      return error
    }
  })

  return [...new Set(translations)]
}
