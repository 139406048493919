<template>
  <div
    class="relative"
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <div
      class="flex justify-between items-center self-start"
      :class="{
        'mb-1': label && !isFieldCustomStyle,
      }"
    >
      <BaseLabel
        v-if="label"
        :is-field-inline="isFieldCustomStyle"
        :disabled="disabled"
        :icon="labelIcon"
        :icon-tooltip-text="labelIconTooltipText"
        :has-icon-action="hasLabelIconAction"
        :icon-class="labelIconClass"
        @icon-action-click="$emit('label-icon-action-click')"
        @click="focus"
      >
        {{ label }}
      </BaseLabel>

      <slot name="label-right" />
    </div>

    <div class="relative">
      <div
        ref="selectRef"
        class="relative"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      >
        <BaseButton
          v-if="isButton && !isFocused"
          class="simple small"
          :disabled="disabled"
          :track-object="$trackedEvents.noTracking"
          @click="focus(!isFocused)"
        >
          <PlusIcon class="w-5 h-5" />
          {{ displayedValue }}
        </BaseButton>

        <div
          v-else
          class="field flex border border-gray-300 text-xs rounded-lg cursor-pointer select-none transition-colors px-2 py-1.75"
          :class="[
            fieldClass,
            {
              'border-blue-600 border-b-0 rounded-b-none hover:!border-blue-600': isFocused,
              'bg-gray-50 text-gray-400 pointer-events-none': disabled,
              'bg-white hover:border-gray-500': !disabled && !hasError,
              'border-red-500 focus:border-red-500': hasError && !isFocused,
            },
          ]"
          :title="displayedValue"
          :disabled="disabled"
          @click="focus(!isFocused)"
        >
          <p v-if="!isSearchable || !isFocused" class="flex-1 pr-6 text-xs truncate min-h-[18px]">
            {{ displayedValue }}
          </p>

          <input
            v-show="isSearchable && isFocused"
            ref="searchInputRef"
            class="flex-1 border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none"
            v-model="searchValue"
            @click.stop
          />

          <ChevronDownIcon
            v-if="!disabled"
            class="w-5 h-5 text-gray-500 absolute right-3 inset-y-0 my-auto transition-transform"
            :class="{ 'rotate-180': isFocused }"
          />
        </div>

        <div
          class="absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg border-t-gray-300"
          :class="{
            hidden: !isFocused,
            'ring-blue-600 border-blue-600': isFocused && !isButton,
          }"
        >
          <ul class="max-h-40 overflow-y-auto">
            <li
              v-if="nullable"
              class="px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors min-h-[30px]"
              :class="{ 'font-semibold': innerValue === null }"
              @click="handleChange(null)"
            >
              {{ placeholder ?? '-' }}
            </li>
            <li
              v-for="(option, index) in displayedOptions"
              :key="option.key"
              class="px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors group flex items-center gap-x-3"
              :class="{
                'justify-between': !isSubDirectionLeft,
                'border-b border-gray-300 mb-1':
                  index !== displayedOptions.length - 1 && option.group !== displayedOptions[index + 1].group,
              }"
              @click="'action' in option ? option.action() : handleChange(option.key)"
            >
              <template v-if="'children' in option">
                <ChevronLeftIcon v-if="isSubDirectionLeft" class="text-gray-500 w-4 h-4" />
                {{ option.label }}
                <ChevronRightIcon v-if="!isSubDirectionLeft" class="text-gray-500 w-4 h-4" />

                <ul
                  class="absolute top-0 bg-white border border-gray-300 rounded-lg invisible group-hover:visible max-h-40 overflow-y-auto min-w-max"
                  :class="{
                    'right-full -mr-2': isSubDirectionLeft,
                    'left-full -ml-2': !isSubDirectionLeft,
                  }"
                >
                  <li
                    v-for="(child, childKey) in option.children"
                    :key="childKey"
                    class="px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors"
                    :class="{ 'font-bold': isSelected(childKey) }"
                    @click.stop="handleChange(childKey)"
                  >
                    {{ child }}
                  </li>
                </ul>
              </template>

              <BaseAction v-else-if="'action' in option">{{ option.label }}</BaseAction>

              <template v-else>
                {{ option.label }}
              </template>

              <CheckIcon v-if="isSelected(option.key)" class="flex-none text-green-500 w-5 h-5" />
            </li>

            <li v-if="!displayedOptions.length" class="px-3 py-1.5">
              {{ $t('common.enum_field.no_result') }}
            </li>
          </ul>
        </div>
      </div>

      <p v-if="subText" class="mt-1 text-gray-500 whitespace-pre-line">
        {{ subText }}
      </p>

      <p v-if="hasError && errorMessage" class="mt-1 text-red-600 text-xs">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseButton from '@/components/base/button/BaseButton.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/20/solid'

import { computed, inject, nextTick, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'

import type { Component } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import { TOption, TOptions, TSubOption } from '@/types/Option.type'
import { LocaleMessageValue } from 'vue-i18n'

const props = defineProps<{
  modelValue: string | string[] | null
  label?: string
  options: TOptions | LocaleMessageValue
  fieldClass?: string | (string | Record<string, boolean>)[]
  disabled?: boolean
  placeholder?: string
  nullable?: boolean
  multiple?: boolean
  isSearchable?: boolean
  isButton?: boolean
  onlyShowPlaceholder?: boolean
  isSubDirectionLeft?: boolean
  hasError?: boolean
  errorMessage?: string
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | string[]): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const selectRef = ref<HTMLElement | null>(null)
const innerValue = ref<string | null | string[]>(props.modelValue)
const isFocused = ref<boolean>(false)
const searchValue = ref<string>('')
const searchInputRef = ref<HTMLInputElement | null>(null)

const filteredOptions = computed<TOptions | LocaleMessageValue>(() => {
  if (props.isSearchable && searchValue.value) {
    const filteredOptions = Object.entries(props.options).filter(([, option]) => {
      if (typeof option !== 'string') return false

      // Remove diacritics from search value and option
      // Source: https://stackoverflow.com/a/51874002
      const normalizedOption = option.normalize('NFD').replace(/\p{Diacritic}/gu, '')
      const normalizedSearchValue = searchValue.value.normalize('NFD').replace(/\p{Diacritic}/gu, '')

      return RegExp(normalizedSearchValue, 'i').test(normalizedOption)
    })

    return Object.fromEntries(filteredOptions)
  } else {
    return props.options
  }
})

const displayedOptions = computed<(TOption & { key: string })[]>(() =>
  Object.entries(filteredOptions.value).reduce<(TOption & { key: string })[]>((list, [key, value], index) => {
    const lastGroup = list[index - 1]?.group ?? 'group'

    if (typeof value === 'string') {
      list.push({
        key,
        label: value,
        group: lastGroup,
      })
    } else {
      list.push({
        key,
        group: value.group ?? lastGroup,
        ...value,
      })
    }

    return list
  }, []),
)

const displayedValue = computed(() => {
  let value: string | undefined

  if (props.onlyShowPlaceholder && props.placeholder) {
    value = props.placeholder
  } else if (
    (!innerValue.value || (Array.isArray(innerValue.value) && innerValue.value.length === 0)) &&
    props.placeholder
  ) {
    value = props.placeholder
  } else if (typeof innerValue.value === 'string') {
    const option =
      displayedOptions.value.find((option) => option.key === innerValue.value)?.label ||
      (
        Object.values(props.options)
          .filter((option) => typeof option !== 'string')
          .find((option) => (option as TSubOption)?.children[innerValue.value as string]) as TSubOption | undefined
      )?.children[innerValue.value]

    if (typeof option === 'string') {
      value = option
    }
  } else {
    value = (innerValue.value as string[])
      ?.map((value) => displayedOptions.value.find((option) => option.key === value)?.label)
      .join(', ')
  }

  return value
})

const focus = async (isFocus = true): Promise<void> => {
  if (!props.disabled) {
    if (!isFocused.value) {
      isFocused.value = isFocus

      if (isFocus) {
        emit('focus')
        await nextTick()
        searchInputRef.value?.focus()
      }
    } else {
      isFocused.value = isFocus
      emit('blur')
    }
  }
}

const handleChange = (value: string | null): void => {
  if (props.multiple) {
    if (value && Array.isArray(innerValue.value)) {
      const valueIndex = innerValue.value.indexOf(value)
      valueIndex >= 0 ? innerValue.value.splice(valueIndex, 1) : innerValue.value.push(value)
    }
  } else {
    const option = displayedOptions.value.find((option) => option.key === value)
    const hasChildren = !!option && 'children' in option

    if (!hasChildren) {
      innerValue.value = value
    }

    isFocused.value = false
    emit('blur')
  }
}

const isSelected = (value: string): boolean => {
  return (
    !!innerValue.value &&
    ((Array.isArray(innerValue.value) && (innerValue.value as string[]).indexOf(value) >= 0) ||
      innerValue.value === value)
  )
}

onClickOutside(selectRef, () => focus(false), {
  ignore: [searchInputRef],
})

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => {
    emit('update:modelValue', value)
  },
  { deep: true },
)
</script>
