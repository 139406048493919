export enum EFieldKind {
  String = 'string',
  Enum = 'enum',
  MultiEnum = 'multi-enum',
  Owner = 'owner',
  SimpleBankGroup = 'simple_bank_group',
  SimpleBankGroupClient = 'simple_bank_group_client',
  MultipleBankGroup = 'multiple_bank_group',
  Date = 'date',
  Bool = 'bool',
  Amount = 'amount',
  Int = 'int',
  SmallInt = 'small_int',
  Rate = 'rate',
  Float = 'float',
  Country = 'country',
  Income = 'income',
  ExtraIncome = 'extra_income',
  RentalIncome = 'rental_income',
  Localisation = 'localisation',
  City = 'city',
  Negative = 'negative',
  Phone = 'phone',
  Percent = 'percent',
  RadioGroup = 'radio-group',
  Text = 'text',
  Usage = 'usage',
  BuildingType = 'building_type',
  PropertyRightType = 'property_right_type',
}
