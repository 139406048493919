<template>
  <div class="text-white">
    <p class="font-bold text-sm">{{ title }}</p>
    <ul class="font-medium text-sm leading-6">
      <li v-for="error in errors" :key="error.path">{{ error.message }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useFieldHelpers } from '@/utils/composables/field.helpers'

defineProps<{
  title: string
  errors: { path: string; message: string }[]
}>()

const { goToField } = useFieldHelpers()
// TODO: add  `@click="goToField(error.path)"` to the li element when path is good in bankRequestValidation
</script>
