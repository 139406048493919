import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelText as _vModelText, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id", "placeholder"]
const _hoisted_2 = {
  key: 0,
  class: "mt-1 text-gray-500"
}

import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PercentField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    placeholder: {},
    isHighlighted: { type: Boolean },
    disabled: { type: Boolean },
    actionText: {},
    unit: {},
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    subText: {}
  },
  emits: ["update:modelValue", "blur", "focus", "action-click", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { n, t } = useI18n()

const numberfieldId = ref<string>(crypto.randomUUID())

const formatValue = (value: number | null): string | null => {
  const defaultValue = props.placeholder ? null : '-'
  const val = value && value > 100 ? 100 : value && value < 0 ? 0 : value

  return typeof val === 'number' ? n(val, 'rate') : defaultValue
}

const parseValue = (value: string | null): number | null => {
  let parsedValue: number | null = null

  if (typeof value === 'string') {
    parsedValue = Number(value.replace(',', '.').replace(/\s/g, ''))
    parsedValue = parsedValue > 100 ? 100 : parsedValue < 0 ? 0 : parsedValue
  }

  return parsedValue
}

const innerValue = ref<string | null>(formatValue(props.modelValue))
const displayedUnit = computed<string>(() => props.unit ?? t('common.units.percent'))
const isFocused = ref<boolean>(false)

const handleFocus = (): void => {
  emit('focus')
  isFocused.value = true
  innerValue.value = props.modelValue ? String(props.modelValue) : ''
}

const handleBlur = (): void => {
  emit('blur')
  isFocused.value = false
  const newVal = parseValue(innerValue.value)
  emit('update:modelValue', newVal)
  innerValue.value = formatValue(newVal)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = formatValue(value)),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex justify-between items-center", {
        'mb-1': !isFieldCustomStyle.value,
      }])
        }, [
          _createVNode(BaseLabel, {
            for: numberfieldId.value,
            "is-field-inline": isFieldCustomStyle.value,
            "label-id": numberfieldId.value,
            unit: displayedUnit.value,
            icon: _ctx.labelIcon,
            "icon-tooltip-text": _ctx.labelIconTooltipText,
            "has-icon-action": _ctx.hasLabelIconAction,
            "label-icon-class": _ctx.labelIconClass,
            onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["for", "is-field-inline", "label-id", "unit", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]),
          (_ctx.actionText)
            ? (_openBlock(), _createBlock(BaseAction, {
                key: 0,
                class: "text-right",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action-click')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        id: numberfieldId.value,
        class: _normalizeClass(["field flex-1 border border-gray-300 text-xs rounded-lg focus:border-blue-500 focus:ring-0 block w-full px-2 py-1 transition-colors", [
          _ctx.fieldClass,
          {
            highlight: _ctx.isHighlighted,
            'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
            'bg-white hover:border-gray-500 focus:border-blue-600': !_ctx.disabled,
          },
        ]]),
        type: "text",
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((innerValue).value = $event)),
        onFocus: handleFocus,
        onBlur: handleBlur,
        onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, null, 42, _hoisted_1), [
        [_vModelText, innerValue.value]
      ]),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})