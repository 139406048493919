import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { computed } from 'vue'
import { useAnalytics } from '@/utils/composables/segment'

import { IEvent } from '@/utils/tracking'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAction',
  props: {
    disabled: { type: Boolean },
    isLoading: { type: Boolean },
    trackObject: {},
    trackProps: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isButtonDisabled = computed(() => props.disabled || props.isLoading)

const { trackEvent } = useAnalytics()

const handleClick = (event: Event) => {
  if (props.trackObject) {
    trackEvent(props.trackObject, props.trackProps)
  }

  emit('click', event)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "text-blue-600 hover:text-blue-700 transition-colors",
    disabled: isButtonDisabled.value,
    onClick: handleClick
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(SpinnerIcon, {
          key: 0,
          class: "inline w-5 h-5 mr-2"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}
}

})