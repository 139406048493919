import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BrokerQuery from '@/schemas/Broker.query.gql'

import IBroker from '@/types/Broker.interface'
import IBrokerQuery from '@/types/schemas/BrokerQuery.interface'

const { getProjectId } = useServiceHelper()

const getBroker = async (): Promise<IBroker | null> => {
  const { data } = await useQuery<IBrokerQuery>({
    query: BrokerQuery,
    variables: {
      projectId: getProjectId(),
    },
  })

  return data.broker
}

export const useBrokerService = () => ({
  getBroker,
})
