<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2 items-start': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :is-field-inline="isFieldCustomStyle"
      :disabled="disabled"
      :has-error="hasError"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @click="focus()"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div>
      <div
        ref="localisationRef"
        class="relative"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      >
        <div
          class="field border border-gray-300 rounded-lg flex items-center px-2 py-1.5 cursor-text transition-colors"
          :class="[
            fieldClass,
            {
              'border-blue-600  hover:border-blue-600': isFocused,
              'bg-gray-50 text-gray-400 pointer-events-none': disabled,
              'bg-white hover:border-gray-500': !disabled && !hasError,
              'border-red-300 bg-red-50 focus:border-red-500': hasError,
              'border-b-0 rounded-b-none': isFocused && results.length > 0,
            },
          ]"
        >
          <label @click="focus()">
            <MapPinIcon
              class="w-5 h-5 mr-2"
              :class="{
                'text-gray-400': disabled,
                'text-gray-500': !disabled,
              }"
            />
          </label>

          <p class="flex-1 pr-6 text-xs truncate min-h-[18px]" v-if="!isFocused" @click="focus(!isFocused)">
            {{ displayedValue }}
          </p>

          <input
            v-show="isFocused"
            ref="localisationInputRef"
            class="flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none"
            v-model="displayedValue"
            @blur="handleBlur"
          />
        </div>

        <div
          v-show="results.length"
          class="absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg"
          :class="{
            hidden: !isFocused,
            'ring-blue-500 border-blue-500 border-t-gray-300': isFocused,
          }"
        >
          <ul class="max-h-40 overflow-y-auto">
            <li
              v-for="result in results"
              :key="result.label"
              class="px-3 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors"
              @click="handleChange(result)"
            >
              {{ result.label }}
            </li>
          </ul>
        </div>
      </div>

      <BaseAction class="mt-1 text-xs" @click="handleModalOpen">
        {{ $t('common.localisation_field.unknown_address') }}
      </BaseAction>
    </div>

    <BaseModal
      :title="$t('common.localisation_field.modal.title')"
      :is-visible="isAddressModalVisible"
      @close="handleModalClose"
      @validate="handleValidate"
    >
      <CityField v-model="addressModalValue" :label="$t('common.localisation_field.modal.city')" />
      <TextField v-model="addressModalValue.street" :label="$t('common.localisation_field.modal.street')" />

      <p class="text-base text-gray-500">
        {{ $t('common.localisation_field.modal.address') }} : {{ formatAddress(addressModalValue) }}
      </p>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import BaseModal from '@/components/base/modal/BaseModal.vue'
import CityField from './CityField.vue'
import TextField from './TextField.vue'
import { MapPinIcon } from '@heroicons/vue/20/solid'

import { addressSearch } from '@pretto/places'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { trackingKey } from '@/utils/tracking'
import { useAnalytics } from '@/utils/composables/segment'
import { useFieldHelpers } from '@/utils/composables/field.helpers'
import { useFormatHelper } from '@/utils/composables/format.helpers'

import type { Component } from 'vue'
import IAddress from '@/types/Address.interface'

const block = inject<string>('block')

interface AddressSearchResult {
  label: string
  value: {
    city: string | null
    country: string | null
    street: string | null
    zipcode: string | null
  }
}

const props = defineProps<{
  modelValue: IAddress
  label?: string
  fieldClass?: string | (string | Record<string, boolean>)[]
  disabled?: boolean
  hasError?: boolean
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: IAddress): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)
const { formatAddress } = useFormatHelper()

const localisationRef = ref<HTMLElement | null>(null)
const localisationInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<IAddress>(props.modelValue)
const isFocused = ref<boolean>(false)
const displayedValue = ref<string>(formatAddress(props.modelValue))
const results = ref<AddressSearchResult[]>([])
const isAddressModalVisible = ref<boolean>(false)

const focus = async (isFocus = true): Promise<void> => {
  isFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (localisationInputRef.value) {
      await nextTick()
      localisationInputRef.value.focus()
      await getResults()
    }
  } else {
    emit('blur')
  }
}

const handleChange = (result: AddressSearchResult): void => {
  innerValue.value = result.value
  displayedValue.value = result.label
  isFocused.value = false
  emit('blur')
}

const addressModalValue = ref<IAddress>({ ...innerValue.value })
const handleValidate = (): void => {
  innerValue.value = { ...addressModalValue.value }
  isAddressModalVisible.value = false
  displayedValue.value = formatAddress(innerValue.value)
}

const { trackEvent } = useAnalytics()
const trackedEvents = inject(trackingKey)

const handleModalOpen = (): void => {
  if (trackedEvents) {
    trackEvent(trackedEvents.localisationFieldAction, {
      object: block,
    })
  }

  isAddressModalVisible.value = true
}

const handleModalClose = (): void => {
  isAddressModalVisible.value = false
  addressModalValue.value = { ...innerValue.value }
}

const getResults = async (): Promise<void> => {
  try {
    const response = await addressSearch.get(displayedValue.value.trim())
    if (typeof response !== 'string') {
      results.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}

const handleBlur = (): void => {
  if (!displayedValue.value) {
    innerValue.value = {
      street: null,
      city: null,
      zipcode: null,
      country: null,
    }
  } else {
    displayedValue.value = formatAddress(innerValue.value)
  }
}

onClickOutside(localisationRef, () => focus(false))

watch(() => displayedValue.value, getResults)

watch(
  () => props.modelValue,
  (value) => {
    innerValue.value = value
    displayedValue.value = formatAddress(innerValue.value)
  },
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)
</script>
