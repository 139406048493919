<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <BaseLabel
      v-if="label"
      :for="textfieldId"
      class="self-start"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
      :disabled="disabled"
      :unit="displayedUnit"
      :is-field-inline="isFieldCustomStyle"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
    </BaseLabel>

    <div class="template-income grid gap-2">
      <NumberField class="area-field1" v-model="innerValue.amount" :disabled="disabled" />

      <EnumField class="area-field2" v-model="innerValue.taxes" :options="taxesOptions" :disabled="disabled" />

      <EnumField class="area-field3" v-model="innerValue.period" :options="periodOptions" :disabled="disabled" />

      <button
        v-if="isDeletable"
        type="button"
        class="area-button"
        :class="{
          'text-gray-300': disabled,
          'text-gray-500 hover:text-gray-600 transition-colors': !disabled,
        }"
        :disabled="disabled"
        @click="deleteIncome"
      >
        <XMarkIcon class="w-5 h-5" />
      </button>

      <p v-if="subText" class="area-subtext col-span-full text-gray-500">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import NumberField from './NumberField.vue'
import EnumField from './EnumField.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Component } from 'vue'
import IIncome from '@/types/Income.interface'

const props = defineProps<{
  modelValue: IIncome
  disabled?: boolean
  fieldClass?: string | (string | Record<string, boolean>)[]
  hasLabelIconAction?: boolean
  isDeletable?: boolean
  label?: string
  labelIcon?: Component
  labelIconClass?: string
  labelIconTooltipText?: string
  placeholder?: string
  subText?: string
  unit?: string
}>()

const emit = defineEmits<{
  (e: 'label-icon-action-click'): void
  (e: 'update:modelValue', value: IIncome): void
}>()

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { t, tm } = useI18n()

const innerValue = ref<IIncome>(props.modelValue)
const textfieldId = ref<string>(crypto.randomUUID())
const displayedUnit = computed<string>(() => props.unit ?? t('common.units.currency'))

const taxesOptions: Record<string, string> = tm('common.income_field.taxes_options')
const periodOptions: Record<string, string> = tm('common.income_field.period_options')

const deleteIncome = async (): Promise<void> => {
  innerValue.value = {
    amount: null,
    period: null,
    taxes: null,
  }
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)
</script>

<style scoped>
.template-income {
  grid-template-areas:
    'field1 button'
    'field2 .'
    'field3 .'
    'subtext .';
  grid-template-columns: 1fr auto;
}

.area-field1 {
  grid-area: field1;
}
.area-field2 {
  grid-area: field2;
}
.area-field3 {
  grid-area: field3;
}

.area-button {
  grid-area: button;
}

.area-subtext {
  grid-area: subtext;
}

@container form (min-width: 32rem) {
  .template-income {
    grid-template-areas:
      'field1 field2 field3 button'
      'subtext subtext subtext subtext';
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
