<template>
  <div class="relative">
    <BaseLabel
      v-if="label"
      :for="textareafieldId"
      class="mb-1"
      :disabled="disabled"
      :has-error="hasError"
      :label-id="textareafieldId"
      :icon="labelIcon"
      :icon-tooltip-text="labelIconTooltipText"
      :has-icon-action="hasLabelIconAction"
      :label-icon-class="labelIconClass"
      @icon-action-click="$emit('label-icon-action-click')"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </BaseLabel>

    <div
      class="grid relative"
      :class="{
        'area-content after:block after:invisible after:p-4 after:text-xs after:whitespace-pre-wrap after:leading-6':
          isHeightInfinite,
      }"
      :data-value="innerValue"
    >
      <textarea
        :id="textareafieldId"
        class="field border border-gray-300 text-xs rounded-lg focus:ring-0 focus:border-blue-600 block w-full p-4 transition-colors"
        :class="[
          fieldClass,
          {
            'bg-white hover:border-gray-500  focus:hover:border-blue-600': !disabled && !hasError,
            'bg-white text-gray-400 pointer-events-none': disabled,
            'min-h-[250px]': !rows,
            'border-red-300 bg-red-50 focus:border-red-500': hasError,
            'resize-none overflow-hidden': isHeightInfinite,
          },
        ]"
        :placeholder="placeholder"
        :spellcheck="spellcheck"
        :rows="rows"
        v-model="innerValue"
        @input="emit('input')"
        @focus="emit('focus')"
        @blur="emit('blur')"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      />

      <SpinnerIcon v-if="isLoading" class="absolute inset-y-0 my-auto left-2 w-5 h-5 text-gray-400" />
    </div>

    <p v-if="subText" class="mt-1 text-gray-500 text-xs">
      {{ subText }}
    </p>

    <p v-if="hasError && errorMessage" class="mt-1 text-red-600 text-xs">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { inject, ref, watch } from 'vue'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

const props = defineProps<{
  modelValue: string | null
  label?: string
  fieldClass?: string | Record<string, boolean>
  placeholder?: string
  disabled?: boolean
  spellcheck?: boolean
  rows?: string | number
  hasError?: boolean
  errorMessage?: string
  required?: boolean
  labelIcon?: Component
  labelIconTooltipText?: string
  hasLabelIconAction?: boolean
  labelIconClass?: string
  isHeightInfinite?: boolean
  isLoading?: boolean
  subText?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'input'): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'label-icon-action-click'): void
}>()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const innerValue = ref<string>(props.modelValue ?? '')
const textareafieldId = ref<string>(crypto.randomUUID())

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value ?? ''),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)
</script>

<style scoped>
/* Source: https://polypane.app/blog/field-sizing-just-works/
field-sizing is still in experimental but will be available in Chromium v123
*/
.area-content:after {
  content: attr(data-value) ' ';
}

.area-content:after,
.area-content textarea {
  grid-area: 1/1/2/2;
}
</style>
