<template>
  <div
    :class="{
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle && label,
    }"
  >
    <div
      v-if="label"
      class="flex justify-between items-center self-start"
      :class="{
        'mb-1': !isFieldCustomStyle,
      }"
    >
      <BaseLabel
        :for="numberfieldId"
        :is-field-inline="isFieldCustomStyle"
        :label-id="numberfieldId"
        :unit="displayedUnit"
        :has-error="hasError"
        :icon="labelIcon"
        :icon-tooltip-text="labelIconTooltipText"
        :has-icon-action="hasLabelIconAction"
        :icon-class="labelIconClass"
        @icon-action-click="$emit('label-icon-action-click')"
      >
        {{ label }}
      </BaseLabel>

      <BaseAction v-if="actionText" class="text-right" @click="$emit('action-click')">
        {{ actionText }}
      </BaseAction>
    </div>

    <div>
      <input
        :id="numberfieldId"
        class="field border border-gray-300 text-xs rounded-lg focus:ring-0 block w-full px-2 py-1 transition-colors"
        :class="[
          fieldClass,
          {
            highlight: isHighlighted,
            'bg-white hover:border-gray-500': !disabled && !hasError,
            'bg-gray-50 text-gray-400 pointer-events-none': disabled,
            'border-red-300 bg-red-50 text-red-600 focus:border-red-500': hasError,
          },
        ]"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="innerValue"
        @focus="handleFocus"
        @blur="handleBlur"
        @mouseenter="fieldHoverHelper?.handleMouseEnter"
        @mouseleave="fieldHoverHelper?.handleMouseLeave"
      />

      <p v-if="subText" class="mt-1 text-gray-500">
        {{ subText }}
      </p>
      <p v-if="hasError && errorMessage" class="mt-0.5 text-red-600 text-2xs">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'
import { EFieldKind } from '@/types/FieldKind.enum'

const props = withDefaults(
  defineProps<{
    modelValue: number | null
    label?: string
    fieldClass?: string | (string | Record<string, boolean>)[]
    placeholder?: string
    min?: number
    max?: number
    hasError?: boolean
    errorMessage?: string
    kind?: EFieldKind
    isHighlighted?: boolean
    disabled?: boolean
    rightLabel?: string
    unit?: string
    actionText?: string
    toggleValue?: boolean
    labelIcon?: Component
    labelIconTooltipText?: string
    hasLabelIconAction?: boolean
    labelIconClass?: string
    subText?: string
    nullable?: boolean
  }>(),
  {
    min: 0,
    nullable: true,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null): void
  (e: 'update:toggleValue', value: boolean): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'action-click'): void
  (e: 'label-icon-action-click'): void
}>()

const { n, t } = useI18n()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const numberfieldId = ref<string>(crypto.randomUUID())

const formatValue = (value: number | null): string | null => {
  let formattedValue: null | string = null

  if (typeof value === 'number') {
    if (props.kind === EFieldKind.Amount || props.kind === EFieldKind.Int) {
      formattedValue = n(value)
    } else {
      formattedValue = n(value, 'rate')
    }
  }

  return formattedValue
}

const parseValue = (value: string | null): number | null => {
  let parsedValue: number | null = props.nullable ? null : 0

  if (value) {
    parsedValue = parseFloat(value.replace(',', '.').replace(/\s/g, ''))
    parsedValue = props.kind === EFieldKind.Int ? Math.round(parsedValue) : parsedValue
  }

  return parsedValue
}

const innerValue = ref<string | null>(formatValue(props.modelValue))
const displayedUnit = computed<string | undefined>(
  () =>
    props.unit ??
    (props.kind === EFieldKind.Rate ? t('common.units.percent') : undefined) ??
    (props.kind === EFieldKind.Amount ? t('common.units.currency') : undefined),
)
const innerToggleValue = ref<boolean>(!!props.toggleValue)
const isFocused = ref<boolean>(false)

const handleFocus = (): void => {
  emit('focus')
  isFocused.value = true
  innerValue.value = props.modelValue ? String(props.modelValue) : ''
}

const handleBlur = (): void => {
  emit('blur')
  isFocused.value = false
  const newVal = parseValue(innerValue.value)
  emit('update:modelValue', newVal)
  innerValue.value = formatValue(newVal)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = formatValue(value)),
)

watch(
  () => props.toggleValue,
  (value) => (innerToggleValue.value = !!value),
)

watch(
  () => innerToggleValue.value,
  (value) => emit('update:toggleValue', value),
)
</script>
