<template>
  <div class="flex flex-col gap-y-2">
    <EnumField
      :label="$t('common.extra_income_field.kind_label')"
      v-model="innerValue.kind"
      :options="kindOptions"
      :disabled="disabled"
    />

    <EnumField
      v-if="options && isRent"
      :label="$t('project_view.mortgagor.immovable_linked_label')"
      :model-value="linkedPropertyId ?? null"
      :options="options"
      :has-error="!linkedPropertyId"
      :error-message="$t('project_view.mortgagor.immovable_error_message')"
      @update:model-value="(value) => handlePropertyIdUpdate(value as string)"
    />

    <div
      :class="{
        '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle,
      }"
    >
      <BaseLabel
        class="self-start"
        :class="{
          'mb-1': !isFieldCustomStyle,
        }"
        :disabled="disabled"
        :is-field-inline="isFieldCustomStyle"
        :unit="$t('common.units.currency')"
        :icon="labelIcon"
      >
        {{ $t('common.extra_income_field.amount_label') }}
      </BaseLabel>

      <div class="flex flex-col gap-y-2">
        <NumberField :field-class="fieldClass" v-model="innerValue.amount" :disabled="disabled" />

        <EnumField
          :field-class="fieldClass"
          v-model="innerValue.period"
          :options="periodOptions"
          :disabled="disabled"
        />

        <p v-if="subText" class="text-gray-500">
          {{ subText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseLabel from '@/components/base/label/BaseLabel.vue'
import EnumField from './EnumField.vue'
import NumberField from './NumberField.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { TOptions } from '@/types/Option.type'
import type { Component } from 'vue'
import IExtraIncome from '@/types/ExtraIncome.interface'
import { EExtraIncome } from '@/types/ExtraIncome.enum'

const props = defineProps<{
  modelValue: IExtraIncome
  fieldClass?: string | (string | Record<string, boolean>)[]
  placeholder?: string
  disabled?: boolean
  subText?: string
  options?: TOptions
  linkedPropertyId?: string
  labelIcon?: Component
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: IExtraIncome): void
  (e: 'update:linkedPropertyId', value: string): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const innerValue = ref<IExtraIncome>(props.modelValue)

const { tm } = useI18n()
const kindOptions: Record<string, string> = tm('common.extra_income_field.kind_options')
const periodOptions: Record<string, string> = tm('common.extra_income_field.period_options')

const handlePropertyIdUpdate = (id: string): void => {
  emit('update:linkedPropertyId', id)
}

const isRent = computed<boolean>(
  () =>
    !!innerValue.value.kind &&
    [EExtraIncome.Rent, EExtraIncome.SeasonalRent, EExtraIncome.CommercialRent].includes(innerValue.value.kind),
)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
  { deep: true },
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
  { deep: true },
)
</script>
