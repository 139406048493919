import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelText as _vModelText, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id", "placeholder", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "mt-1 text-gray-500"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-0.5 text-red-600 text-2xs"
}

import BaseAction from '@/components/base/action/BaseAction.vue'
import BaseLabel from '@/components/base/label/BaseLabel.vue'

import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'
import { EFieldKind } from '@/types/FieldKind.enum'


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    placeholder: {},
    min: { default: 0 },
    max: {},
    hasError: { type: Boolean },
    errorMessage: {},
    kind: {},
    isHighlighted: { type: Boolean },
    disabled: { type: Boolean },
    rightLabel: {},
    unit: {},
    actionText: {},
    toggleValue: { type: Boolean },
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {},
    subText: {},
    nullable: { type: Boolean, default: true }
  },
  emits: ["update:modelValue", "update:toggleValue", "blur", "focus", "action-click", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { n, t } = useI18n()

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const numberfieldId = ref<string>(crypto.randomUUID())

const formatValue = (value: number | null): string | null => {
  let formattedValue: null | string = null

  if (typeof value === 'number') {
    if (props.kind === EFieldKind.Amount || props.kind === EFieldKind.Int) {
      formattedValue = n(value)
    } else {
      formattedValue = n(value, 'rate')
    }
  }

  return formattedValue
}

const parseValue = (value: string | null): number | null => {
  let parsedValue: number | null = props.nullable ? null : 0

  if (value) {
    parsedValue = parseFloat(value.replace(',', '.').replace(/\s/g, ''))
    parsedValue = props.kind === EFieldKind.Int ? Math.round(parsedValue) : parsedValue
  }

  return parsedValue
}

const innerValue = ref<string | null>(formatValue(props.modelValue))
const displayedUnit = computed<string | undefined>(
  () =>
    props.unit ??
    (props.kind === EFieldKind.Rate ? t('common.units.percent') : undefined) ??
    (props.kind === EFieldKind.Amount ? t('common.units.currency') : undefined),
)
const innerToggleValue = ref<boolean>(!!props.toggleValue)
const isFocused = ref<boolean>(false)

const handleFocus = (): void => {
  emit('focus')
  isFocused.value = true
  innerValue.value = props.modelValue ? String(props.modelValue) : ''
}

const handleBlur = (): void => {
  emit('blur')
  isFocused.value = false
  const newVal = parseValue(innerValue.value)
  emit('update:modelValue', newVal)
  innerValue.value = formatValue(newVal)
}

watch(
  () => props.modelValue,
  (value) => (innerValue.value = formatValue(value)),
)

watch(
  () => props.toggleValue,
  (value) => (innerToggleValue.value = !!value),
)

watch(
  () => innerToggleValue.value,
  (value) => emit('update:toggleValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex justify-between items-center self-start", {
        'mb-1': !isFieldCustomStyle.value,
      }])
        }, [
          _createVNode(BaseLabel, {
            for: numberfieldId.value,
            "is-field-inline": isFieldCustomStyle.value,
            "label-id": numberfieldId.value,
            unit: displayedUnit.value,
            "has-error": _ctx.hasError,
            icon: _ctx.labelIcon,
            "icon-tooltip-text": _ctx.labelIconTooltipText,
            "has-icon-action": _ctx.hasLabelIconAction,
            "icon-class": _ctx.labelIconClass,
            onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["for", "is-field-inline", "label-id", "unit", "has-error", "icon", "icon-tooltip-text", "has-icon-action", "icon-class"]),
          (_ctx.actionText)
            ? (_openBlock(), _createBlock(BaseAction, {
                key: 0,
                class: "text-right",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action-click')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        id: numberfieldId.value,
        class: _normalizeClass(["field border border-gray-300 text-xs rounded-lg focus:ring-0 block w-full px-2 py-1 transition-colors", [
          _ctx.fieldClass,
          {
            highlight: _ctx.isHighlighted,
            'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
            'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
            'border-red-300 bg-red-50 text-red-600 focus:border-red-500': _ctx.hasError,
          },
        ]]),
        type: "text",
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((innerValue).value = $event)),
        onFocus: handleFocus,
        onBlur: handleBlur,
        onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
        onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
      }, null, 42, _hoisted_1), [
        [_vModelText, innerValue.value]
      ]),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true),
      (_ctx.hasError && _ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})