import BankField from '@/components/base/fields/BankField.vue'
import BoolField from '@/components/base/fields/BoolField.vue'
import CityField from '@/components/base/fields/CityField.vue'
import CountryField from '@/components/base/fields/CountryField.vue'
import DateField from '@/components/base/fields/DateField.vue'
import EnumField from '@/components/base/fields/EnumField.vue'
import ExtraIncomeField from '@/components/base/fields/ExtraIncomeField.vue'
import IncomeField from '@/components/base/fields/IncomeField.vue'
import LocalisationField from '@/components/base/fields/LocalisationField.vue'
import NumberField from '@/components/base/fields/NumberField.vue'
import IncrementField from '@/components/base/fields/IncrementField.vue'
import PercentField from '@/components/base/fields/PercentField.vue'
import PhoneField from '@/components/base/fields/PhoneField.vue'
import RentalIncomeField from '@/components/base/fields/RentalIncomeField.vue'
import TextField from '@/components/base/fields/TextField.vue'
import TextareaField from '@/components/base/fields/TextareaField.vue'
import BaseSegmentedBar from '@/components/base/segmented-bar/BaseSegmentedBar.vue'

import { nextTick, type InjectionKey, type Ref } from 'vue'
import { EFieldKind } from '@/types/FieldKind.enum'
import { useSectionRegistration } from './section_registration'
import { sendWarningMessage } from '../messages'
import i18n from '../i18n'

const getComponent = (kind: EFieldKind) => {
  switch (kind) {
    case EFieldKind.String:
      return TextField
    case EFieldKind.Text:
      return TextareaField
    case EFieldKind.Enum:
    case EFieldKind.MultiEnum:
    case EFieldKind.Owner:
      return EnumField
    case EFieldKind.SimpleBankGroup:
    case EFieldKind.SimpleBankGroupClient:
    case EFieldKind.MultipleBankGroup:
      return BankField
    case EFieldKind.Date:
      return DateField
    case EFieldKind.Bool:
      return BoolField
    case EFieldKind.Int:
    case EFieldKind.Rate:
    case EFieldKind.Amount:
    case EFieldKind.Negative:
    case EFieldKind.Float:
      return NumberField
    case EFieldKind.SmallInt:
      return IncrementField
    case EFieldKind.Percent:
      return PercentField
    case EFieldKind.Country:
      return CountryField
    case EFieldKind.Income:
      return IncomeField
    case EFieldKind.ExtraIncome:
      return ExtraIncomeField
    case EFieldKind.RentalIncome:
      return RentalIncomeField
    case EFieldKind.Localisation:
      return LocalisationField
    case EFieldKind.City:
      return CityField
    case EFieldKind.Phone:
      return PhoneField
    case EFieldKind.RadioGroup:
      return BaseSegmentedBar
    default:
      return TextField
  }
}

const fieldHoverKey = Symbol() as InjectionKey<{
  isHovering: Ref<boolean>
  handleMouseEnter: () => void
  handleMouseLeave: () => void
}>

const { t } = i18n.global
const { sectionList } = useSectionRegistration()

const goToField = async (path: string): Promise<void> => {
  const section = sectionList.find((section) => section.paths.value.includes(path))

  if (section) {
    section.isCollapsed.value = false
    await nextTick()
  }

  const element = document.querySelector(`[data-field="${btoa(path)}"]`)

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })

    element.classList.add('field-anim')
    setTimeout(() => {
      element.classList.remove('field-anim')
    }, 2000)
  } else {
    sendWarningMessage(t('warnings.go_to_field.text', { path }))
  }
}

export const useFieldHelpers = () => ({
  fieldHoverKey,
  getComponent,
  goToField,
})
