import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-x-4 text-white" }
const _hoisted_2 = { class: "font-bold text-sm" }
const _hoisted_3 = {
  key: 0,
  class: "font-medium text-sm"
}

import BaseButton from '@/components/base/button/BaseButton.vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'

import { IEvent } from '@/utils/tracking'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseToast',
  props: {
    title: {},
    trackObject: {},
    actionText: {},
    description: {},
    callback: { type: Function }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ExclamationTriangleIcon), { class: "w-5 h-5" }),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      (!!_ctx.callback && _ctx.trackObject && _ctx.actionText)
        ? (_openBlock(), _createBlock(BaseButton, {
            key: 1,
            class: "mt-1 red simple small",
            "track-object": _ctx.trackObject,
            onClick: _ctx.callback
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.actionText), 1)
            ]),
            _: 1
          }, 8, ["track-object", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})