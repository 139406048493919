import { InjectionKey, Ref, ref, shallowReactive } from 'vue'

interface ISection {
  id: string
  isCollapsed: Ref<boolean>
  paths: Ref<string[]>
}

const sectionList = shallowReactive<ISection[]>([])

const createSection = (isCollapsed: Ref<boolean>): ISection => {
  const section: ISection = {
    id: crypto.randomUUID(),
    isCollapsed: isCollapsed,
    paths: ref<string[]>([]),
  }

  sectionList.push(section)

  return section
}

const removeSection = (id: string): void => {
  const index = sectionList.findIndex((section) => section.id === id)

  if (index !== -1) {
    sectionList.splice(index, 1)
  }
}

const sectionRegistrationInjectionKey = Symbol('sectionRegistration') as InjectionKey<ISection>

export const useSectionRegistration = () => ({
  sectionRegistrationInjectionKey,
  createSection,
  removeSection,
  sectionList,
})
