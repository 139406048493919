<template>
  <button
    type="button"
    class="relative text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center leading-6 transition-colors gap-x-2 min-h-[32px]"
    :class="{
      'focus:ring-0': disabled,
    }"
    :disabled="disabled || isLoading"
    @click="handleClick"
  >
    <SpinnerIcon v-if="isLoading" class="inline w-5 h-5 mr-2 text-white" />

    <slot />

    <span v-if="badgeCount" class="absolute -top-2 -right-3 bg-red-600 text-white w-6 h-6> rounded-full text-xs">
      {{ badgeCount }}
    </span>
  </button>
</template>

<script setup lang="ts">
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'
import { useAnalytics } from '@/utils/composables/segment'
import { IEvent } from '@/utils/tracking'

const props = defineProps<{
  badgeCount?: number
  disabled?: boolean
  isLoading?: boolean
  trackObject: IEvent
  trackProps?: Record<string, string | undefined>
}>()

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()

const { trackEvent } = useAnalytics()

const handleClick = (event: Event) => {
  if (props.trackObject) {
    trackEvent(props.trackObject, props.trackProps)
  }

  emit('click', event)
}
</script>

<style scoped>
.primary {
  @apply text-white bg-blue-600 hover:bg-blue-800 focus:ring-2 focus:ring-blue-300;
}

.danger,
.red {
  @apply text-white bg-red-600 hover:bg-red-800 focus:ring-red-300;
}

.green {
  @apply text-white bg-green-500 hover:bg-green-700 focus:ring-green-300;
}

.gray {
  @apply bg-gray-200 hover:bg-gray-300 focus:ring-gray-100;
}

.orange {
  @apply text-white bg-orange-600 hover:bg-orange-800 focus:ring-orange-300;
}

.simple {
  @apply transition-all border bg-white border-gray-400  hover:bg-white hover:ring-1 hover:border-gray-400 hover:ring-gray-400 focus:ring-gray-200 py-[7px];
}

.transparent {
  @apply bg-transparent hover:bg-transparent;
}

.primary.simple {
  @apply border-blue-600 text-blue-600 hover:border-blue-300 hover:ring-blue-300 focus:ring-blue-300;
}

.red.simple,
.danger.simple {
  @apply text-red-600 border-red-600 hover:border-red-300 hover:ring-red-300 focus:ring-red-300;
}

.green.simple {
  @apply text-green-500 border-green-500 hover:border-green-300 hover:ring-green-300 focus:ring-green-300;
}

.gray.simple {
  @apply border-gray-900 hover:bg-gray-200 focus:ring-gray-400;
}

.white.simple {
  @apply text-white border-white focus:ring-gray-200;
}

.small {
  @apply leading-4 text-xs py-1.5;
}

.small.simple {
  @apply py-[5px];
}

.large {
  @apply text-base py-3;
}

.icon {
  @apply rounded-full p-1.5;
}

button:not(.simple):disabled {
  @apply text-white bg-gray-400 hover:bg-gray-400 border-none;
}

.simple:disabled {
  @apply opacity-50 pointer-events-none;
}

.light {
  @apply bg-transparent hover:bg-gray-100 border-0;
}
</style>
