import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "max-h-40 overflow-y-auto" }
const _hoisted_3 = ["onClick"]

import BaseLabel from '@/components/base/label/BaseLabel.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

import { computed, inject, nextTick, ref, watch } from 'vue'
import { countrySearch } from '@pretto/places'
import prettoPlacesConfig from '@/config/pretto_places.json'
import { onClickOutside } from '@vueuse/core'
import { sendErrorMessage } from '@/utils/messages'
import { useCountryHelper } from '@/utils/composables/country.helpers'
import { useFieldHelpers } from '@/utils/composables/field.helpers'

import type { Component } from 'vue'

interface CountriesSearchResult {
  value: string
  label: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CountryField',
  props: {
    modelValue: {},
    label: {},
    fieldClass: {},
    disabled: { type: Boolean },
    hasError: { type: Boolean },
    leftIcon: {},
    labelIcon: {},
    labelIconTooltipText: {},
    hasLabelIconAction: { type: Boolean },
    labelIconClass: {}
  },
  emits: ["update:modelValue", "blur", "focus", "label-icon-action-click"],
  setup(__props: any, { emit: __emit }) {

const countriesApi = countrySearch.init(prettoPlacesConfig.countriesAppId, prettoPlacesConfig.countriesApiKey)

const props = __props

const emit = __emit

const { fieldHoverKey } = useFieldHelpers()
const fieldHoverHelper = inject(fieldHoverKey, undefined)

const isFieldInline = inject<boolean>('isFieldInline', false)

const isFieldCustomStyle = computed<boolean>(() => isFieldInline)

const { getCountryName } = useCountryHelper()

const countryfieldRef = ref<HTMLElement | null>(null)
const countryInputRef = ref<HTMLInputElement | null>(null)
const innerValue = ref<string | null>(props.modelValue)
const isFocused = ref<boolean>(false)
const searchValue = ref<string>(getCountryName((innerValue.value ?? '').trim()))
const results = ref<CountriesSearchResult[]>([])

const displayedValue = computed(() => {
  return getCountryName((innerValue.value ?? '').trim())
})

const focus = async (isFocus = true): Promise<void> => {
  isFocused.value = isFocus

  if (isFocus) {
    emit('focus')
    if (countryInputRef.value) {
      await nextTick()
      countryInputRef.value.focus()
      await getResults()
    }
  } else {
    emit('blur')
  }
}

const handleChange = (result: CountriesSearchResult): void => {
  innerValue.value = result.value
  searchValue.value = result.label
  isFocused.value = false
  emit('blur')
}

const isSelected = (value: string): boolean => {
  return innerValue.value === value
}

const getResults = async (): Promise<void> => {
  try {
    if (typeof countriesApi !== 'string') {
      const response = await countriesApi.get(searchValue.value.trim(), { limit: 10 })
      results.value = response
    }
  } catch (error) {
    sendErrorMessage(error as string)
  }
}

onClickOutside(countryfieldRef, () => focus(false))

watch(() => searchValue.value, getResults)

watch(
  () => props.modelValue,
  (value) => (innerValue.value = value),
)

watch(
  () => innerValue.value,
  (value) => emit('update:modelValue', value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      '@lg/form:grid grid-cols-2 gap-x-2': isFieldCustomStyle.value && _ctx.label,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(BaseLabel, {
          key: 0,
          class: _normalizeClass({
        'mb-1': !isFieldCustomStyle.value,
      }),
          "is-field-inline": isFieldCustomStyle.value,
          disabled: _ctx.disabled,
          "has-error": _ctx.hasError,
          icon: _ctx.labelIcon,
          "icon-tooltip-text": _ctx.labelIconTooltipText,
          "has-icon-action": _ctx.hasLabelIconAction,
          "label-icon-class": _ctx.labelIconClass,
          onIconActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('label-icon-action-click'))),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (focus()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["class", "is-field-inline", "disabled", "has-error", "icon", "icon-tooltip-text", "has-icon-action", "label-icon-class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "countryfieldRef",
      ref: countryfieldRef,
      class: "relative",
      onMouseenter: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseEnter && _unref(fieldHoverHelper)?.handleMouseEnter(...args))),
      onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(fieldHoverHelper)?.handleMouseLeave && _unref(fieldHoverHelper)?.handleMouseLeave(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["field flex border border-gray-300 text-xs rounded-lg cursor-pointer select-none transition-colors px-2 py-1.75", [
          _ctx.fieldClass,
          {
            'border-blue-500 hover:border-blue-600': isFocused.value,
            'border-b-0 rounded-b-none': isFocused.value && results.value.length > 0,
            'bg-gray-50 text-gray-400 pointer-events-none': _ctx.disabled,
            'bg-white hover:border-gray-500': !_ctx.disabled && !_ctx.hasError,
            'border-red-300 bg-red-50 focus:border-red-500': _ctx.hasError,
          },
        ]]),
        title: displayedValue.value
      }, [
        (!isFocused.value)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "flex-1 pr-6 text-xs truncate min-h-[18px]",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (focus(!isFocused.value)))
            }, _toDisplayString(displayedValue.value), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          ref_key: "countryInputRef",
          ref: countryInputRef,
          class: "flex-1 text-xs border-0 p-0 pr-6 bg-transparent focus:ring-0 focus:outline-none",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchValue).value = $event))
        }, null, 512), [
          [_vShow, isFocused.value],
          [_vModelText, searchValue.value]
        ]),
        _createVNode(_unref(ChevronDownIcon), {
          class: _normalizeClass(["w-5 h-5 text-gray-500 absolute right-3 inset-y-0 my-auto transition-transform", { 'rotate-180': isFocused.value && results.value.length > 0 }]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (focus(!isFocused.value)))
        }, null, 8, ["class"])
      ], 10, _hoisted_1),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["absolute z-[3] inset-x-0 top-full bg-white border border-gray-300 rounded-b-lg overflow-hidden", {
          hidden: !isFocused.value,
          'border-blue-500 border-t-gray-300': isFocused.value,
        }])
      }, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results.value, (result) => {
            return (_openBlock(), _createElementBlock("li", {
              key: result.value,
              class: _normalizeClass(["px-2 py-1.5 cursor-pointer hover:bg-blue-50 transition-colors", { 'font-bold': isSelected(result.value) }]),
              onClick: ($event: any) => (handleChange(result))
            }, _toDisplayString(result.label), 11, _hoisted_3))
          }), 128))
        ])
      ], 2), [
        [_vShow, results.value.length]
      ])
    ], 544)
  ], 2))
}
}

})